import { useMemo } from "react";
import { FieldValues } from "react-hook-form";
import {
  FormSelectField,
  FormSelectFieldOptions,
  FormSelectFieldProps,
} from "@/components/form/FormSelectField";
import { OperatorId, Operators } from "@/types/operator";

type Options = FormSelectFieldOptions<OperatorId>;

interface IOperatorFieldProps<TFormValues extends FieldValues>
  extends Omit<FormSelectFieldProps<TFormValues, OperatorId>, "options"> {
  operators: Operators;
}

export const OperatorField = <TFormValues extends FieldValues>({
  label = "Operator",
  operators,
  ...restProps
}: IOperatorFieldProps<TFormValues>) => {
  const options = useMemo<Options>(
    () => convertOperatorsToOptions(operators),
    [operators],
  );

  return <FormSelectField label={label} options={options} {...restProps} />;
};

const convertOperatorsToOptions = (operators: Operators): Options => {
  return operators.map((operator) => ({
    value: operator.id,
    label: operator.name,
  }));
};

import { TransactionType } from "@/types/transaction";
import {
  useApproveWithdrawalTransactionMutation,
  useResendDepositTransactionCallbackMutation,
  useUpdateDepositTransactionStatusMutation,
  useUpdateWithdrawalTransactionStatusMutation,
  useResendWithdrawalTransactionCallbackMutation,
} from "@/features/transactionsManager/transactionsApi";

interface IUseTransactionActionsMutationOptions {
  transactionType: TransactionType;
}

export const useTransactionActionsMutation= ({
  transactionType,
}: IUseTransactionActionsMutationOptions) => {
  const [resendDepositCallback] = useResendDepositTransactionCallbackMutation();
  const [resendWithdrawalCallback] =
    useResendWithdrawalTransactionCallbackMutation();
  const [updateDepositStatus] = useUpdateDepositTransactionStatusMutation();
  const [updateWithdrawalStatus] =
    useUpdateWithdrawalTransactionStatusMutation();
  const [approveWithdrawalTransaction] =
    useApproveWithdrawalTransactionMutation();

  const resendCallbackByTransactionType: Record<
    TransactionType,
    typeof resendDepositCallback | typeof resendWithdrawalCallback
  > = {
    [TransactionType.Deposit]: resendDepositCallback,
    [TransactionType.Withdrawal]: resendWithdrawalCallback,
  };

  const updateStatusByTransactionType: Record<
    TransactionType,
    typeof updateDepositStatus | typeof updateWithdrawalStatus
  > = {
    [TransactionType.Deposit]: updateDepositStatus,
    [TransactionType.Withdrawal]: updateWithdrawalStatus,
  };

  const resendCallbackMutation =
    resendCallbackByTransactionType[transactionType];
  const updateStatusMutation = updateStatusByTransactionType[transactionType];

  return {
    resendCallback: resendCallbackMutation,
    updateStatus: updateStatusMutation,
    approveTransaction: approveWithdrawalTransaction,
  };
};

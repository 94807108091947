import { FC } from "react";
import { AppBar as MuiAppBar, Typography } from "@mui/material";
import { APP_NAME } from "@/constants/common";
import { Button } from "@/components/Button";
import { useSignOutMutation } from "@/features/auth/auth.api";
import { useUser } from "@/hooks/useUser";
import * as Styled from "./AppBar.styles";

export const AppBar: FC = () => {
  const [signOut] = useSignOutMutation();
  const { user } = useUser();

  return (
    <MuiAppBar position="static">
      <Styled.Toolbar>
        <Typography variant="h6" noWrap>
          {APP_NAME}
        </Typography>
        <Styled.ProfileControls>
          <Styled.Text>{user?.fullName || ""}</Styled.Text>
          <Button variant="text" onClick={() => signOut()}>
            Sign out
          </Button>
        </Styled.ProfileControls>
      </Styled.Toolbar>
    </MuiAppBar>
  );
};

import { NavLink as ReactRouterNavLink } from "react-router-dom";
import {
  Drawer as MuiDrawer,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  drawerClasses,
} from "@mui/material";
import { styled } from "@/styles/styled";

const OPEN_DRAWER_WIDTH = 280;

export const Drawer = styled(MuiDrawer)<{ $isOpen: boolean }>(({
  theme,
  $isOpen,
}) => {
  const { transitions } = theme;

  return {
    [`.${drawerClasses.paper}`]: {
      position: "static",
    },
    width: $isOpen ? OPEN_DRAWER_WIDTH : `4.0625rem`,
    flexShrink: 0,
    whiteSpace: "nowrap",
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: $isOpen
        ? transitions.duration.enteringScreen
        : transitions.duration.leavingScreen,
    }),
  };
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  paddingRight: "0.8rem",
}));

export const ListItem = styled(MuiListItem)({
  display: "flex",
  alignItems: "center",
  minHeight: "3rem",
  padding: "0.5rem 1.25rem",
});

export const Link = styled(ReactRouterNavLink)(({ theme }) => {
  const { transitions, palette } = theme;

  return {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: palette.text.primary,
    transition: transitions.create(["opacity", "color"]),

    "&:hover, &:focus": {
      opacity: 0.7,
    },

    "&:active": {
      opacity: 0.6,
    },

    "&.active, &.active:hover, &.active:focus": {
      color: palette.primary.main,
      opacity: 1,
    },
  };
});

export const ListItemIcon = styled(MuiListItemIcon)<{ $isOpen: boolean }>(
  ({ $isOpen }) => ({
    color: "inherit",
    minWidth: 0,
    marginRight: $isOpen ? "1.5rem" : 0,
  }),
);

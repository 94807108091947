import { utcToZonedTime } from "date-fns-tz";

const convertTimezoneToUtcOffset = (timezone: string): string => {
  const now = new Date();
  const zonedNow = utcToZonedTime(now, timezone);

  const utcOffsetInMinutes = zonedNow.getTimezoneOffset();
  const hours = Math.floor(Math.abs(utcOffsetInMinutes) / 60);
  const minutes = Math.abs(utcOffsetInMinutes) % 60;

  const symbol = utcOffsetInMinutes >= 0 ? "-" : "+";
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${symbol}${formattedHours}:${formattedMinutes}`;
};

export const getBrowserUtcOffset = (): string => {
  return convertTimezoneToUtcOffset(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
};

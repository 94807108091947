import { format } from "date-fns";

export enum DateFormat {
  Long = "yyyy-LL-dd HH:mm:ss O",
}

export const formatDate = (
  date: Date | number | string,
  dateFormat = DateFormat.Long,
) => {
  return format(new Date(date), dateFormat);
};

import { FC } from "react";
import { DataGrid } from "@/components/DataGrid";
import {
  IDepositTransaction,
  IGetDepositTransactionsRequestData,
  IGetWithdrawalTransactionsRequestData,
  IWithdrawalTransaction,
} from "@/features/transactionsManager/types";
import {
  TransactionDataGridColumns,
  useTransactionDataGridColumns,
} from "@/features/transactionsManager/components/TransactionDataGrid/useTransactionDataGridColumns";
import { TransactionType } from "@/types/transaction";
import { useSort } from "@/hooks/useSort";
import { TransactionDataGridField } from "@/features/transactionsManager/components/TransactionDataGrid/types";
import { useDataGridColumnsVisibility } from "@/hooks/useDataGridColumnsVisibility";
import { TransactionDataGridToolbar } from "@/features/transactionsManager/components/TransactionDataGridToolbar";

interface ITransactionDataGridProps {
  transactionType: TransactionType;
  className?: string;
  isFetching: boolean;
  items: IDepositTransaction[] | IWithdrawalTransaction[];
  requestData: IGetDepositTransactionsRequestData &
    IGetWithdrawalTransactionsRequestData;
}

export const TransactionDataGrid: FC<ITransactionDataGridProps> = ({
  transactionType,
  className,
  isFetching,
  items,
  requestData,
}) => {
  const { sortData, handleChangeSortData } =
    useSort<TransactionDataGridField>();

  const { transactionColumns } = useTransactionDataGridColumns({
    transactionType,
  });

  const { visibleColumns, hiddenColumnFields, handleChangeVisibleColumns } =
    useDataGridColumnsVisibility<
      TransactionDataGridColumns,
      TransactionDataGridField
    >({ columns: transactionColumns });

  return (
    <>
      <TransactionDataGridToolbar
        transactionColumns={transactionColumns}
        hiddenColumnFields={hiddenColumnFields}
        onVisibleColumnsChange={handleChangeVisibleColumns}
        transactionType={transactionType}
        exportRequestData={requestData}
      />
      <DataGrid
        className={className}
        columns={visibleColumns}
        data={items}
        emptyMessage="Whoops! There're no transactions at the moment :("
        loading={{
          isLoadingTable: isFetching,
        }}
        sortData={sortData}
        onSortChange={handleChangeSortData}
      />
    </>
  );
};

import { api } from "@/store/api";
import {
  IGetMerchantsRequestParams,
  IGetMerchantsResponseBody,
} from "@/features/merchants/types";
import { PAGE_SIZE_WITHOUT_PAGINATION } from "@/constants/common";

const API_PREFIX = "/merchants";

const merchantsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMerchants: builder.query<
      IGetMerchantsResponseBody,
      IGetMerchantsRequestParams
    >({
      query: (params) => ({
        url: API_PREFIX,
        params: {
          pageSize: PAGE_SIZE_WITHOUT_PAGINATION,
          ...params,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetMerchantsQuery } = merchantsApi;

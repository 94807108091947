import { FC, useCallback, useEffect } from "react";
import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { DialogHeader } from "@/components/DialogHeader";
import { TransactionDetailsDataGrid } from "@/features/transactionsManager/components/TransactionDetailsDataGrid";
import { useGetTransactionDetailsQuery } from "@/features/transactionsManager/transactionsApi";
import { handleError } from "@/utils/handleError";
import { TransactionType } from "@/types/transaction";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useTransactionDetailsFilter } from "@/features/transactionsManager/components/TransactionDetailsDialog/useTransactionDetailsFilter";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@/routing/constants";
import * as Styled from "./TransactionDetailsDialog.styles";

interface ITransactionDetailsSearchParams {
  detailsDialog?: {
    search?: string;
  };
}

type TransactionDetailsDialogParams = {
  id: string;
  transactionType: TransactionType;
};

export interface ITransactionDetailsDialogProps extends DialogProps {}

export const TransactionDetailsDialog: FC<ITransactionDetailsDialogProps> = (
  props,
) => {
  const navigate = useNavigate();

  const { id: idParam = "", transactionType = TransactionType.Deposit } =
    useParams<TransactionDetailsDialogParams>();
  const id = parseInt(idParam);

  const { parsedSearchParams, getMergedSearchParams, mergeSearchParams } =
    useSearchParams<ITransactionDetailsSearchParams>();
  const { search = "" } = parsedSearchParams?.detailsDialog || {};

  const { data, isFetching, error } = useGetTransactionDetailsQuery({
    id,
    transactionType,
  });

  const { filteredData } = useTransactionDetailsFilter(
    data?.events || [],
    search,
  );

  const handleSearchChange = useCallback(
    (search: string) => {
      mergeSearchParams({
        detailsDialog: {
          search: search || undefined,
        },
      });
    },
    [mergeSearchParams],
  );

  const handleClose = useCallback(() => {
    const mergedSearchParams = getMergedSearchParams({
      detailsDialog: undefined,
    });

    navigate(
      ROUTES.transactionsManager.createURL(transactionType, mergedSearchParams),
    );
  }, [getMergedSearchParams, navigate, transactionType]);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  return (
    <Dialog maxWidth={false} {...props} onClose={handleClose}>
      <DialogHeader onClose={handleClose}>Transaction Id: {id}</DialogHeader>
      <DialogContent dividers>
        <Styled.DataGridToolbar
          searchValue={search}
          onSearchChange={handleSearchChange}
        />
        <TransactionDetailsDataGrid
          isFetching={isFetching}
          items={filteredData}
        />
      </DialogContent>
    </Dialog>
  );
};

import { useState, MouseEvent, useCallback } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MenuOpen as MenuOpenIcon } from "@mui/icons-material";

export interface IActionsButtonItem<TTitle extends string> {
  title: TTitle;
  onClick: () => void;
  disabled?: boolean;
}

interface IActionsButtonProps<TTitle extends string> {
  items: IActionsButtonItem<TTitle>[];
}

export const ActionsButton = <TTitle extends string>({
  items,
}: IActionsButtonProps<TTitle>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton color="primary" onClick={handleClick}>
        <MenuOpenIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {items.map(({ title, onClick, disabled = false }) => (
          <MenuItem key={title} onClick={onClick} disabled={disabled}>
            {title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

import { formatDuration, intervalToDuration } from "date-fns";

export const formatSecondsInterval = (seconds: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: seconds * 1000,
  });

  return formatDuration(duration);
};

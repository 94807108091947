import { styled, Toolbar as MuiToolbar, Typography } from "@mui/material";

export const Toolbar = styled(MuiToolbar)`
  display: flex;
  justify-content: space-between;
`;

export const ProfileControls = styled("div")`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Text = styled(Typography)`
  margin-bottom: 0.1rem;
`;

import { styled } from "@mui/material";

export const Root = styled("div")`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2rem;
`;

import { FC, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "@/hooks/useUser";
import { ROUTES } from "@/routing/constants";

interface IAuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<IAuthGuardProps> = ({ children }) => {
  const location = useLocation();
  const { user } = useUser();

  if (user) {
    return <>{children}</>;
  }

  return (
    <Navigate
      replace
      state={{ from: location }}
      to={ROUTES.signIn.createURL()}
    />
  );
};

import { useMemo } from "react";
import { useAppSelector } from "@/hooks/useAppSelector";
import { IUserPermissions, UserRole } from "@/types/user";
import { IAuthUser } from "@/features/auth/types";

const getPermissionsByUser = (user: IAuthUser | null): IUserPermissions => ({
  transactions: {
    withOperatorsFilter: user?.role === UserRole.Admin,
    withMerchantsFilter: user?.role === UserRole.Operator,
  },
  users: !!user && [UserRole.Admin, UserRole.Operator].includes(user.role),
});

export const useUser = () => {
  const { user } = useAppSelector((state) => state.auth);

  const permissions = useMemo<IUserPermissions>(
    () => getPermissionsByUser(user),
    [user],
  );

  return { user, permissions };
};

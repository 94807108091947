import {
  CheckboxTreeValues,
  CheckboxTreeNodes,
} from "@/components/CheckboxTree";

export const getSelectValue = (
  nodes: CheckboxTreeNodes,
  checkedValues: CheckboxTreeValues,
  withInverse?: boolean,
): CheckboxTreeValues => {
  let result: CheckboxTreeValues = [];

  nodes.forEach((node) => {
    const { value, label, children } = node;

    if (children) {
      const childrenLabels = getSelectValue(
        children,
        checkedValues,
        withInverse,
      );

      if (childrenLabels.length) {
        result = [...result, label, ...childrenLabels];
      }
    } else {
      const isValueChecked = checkedValues.includes(value);

      if (withInverse ? !isValueChecked : isValueChecked) {
        result.push(label);
      }
    }
  });

  return result;
};

import { UserRole } from "@/types/user";

export interface IAuthUser {
  fullName: string;
  role: UserRole;
  ipWhiteList: string[];
}

export enum AuthStatus {
  RequiredEmailVerification = "requiredEmailVerification",
  WaitingGoogleAuthCode = "waitingGoogleAuthCode",
  WaitingEmailAuthCode = "waitingEmailAuthCode",
  Authorized = "authorized",
  NotAuthorized = "notAuthorized",
}

export interface ISignInRequestBody {
  username: string;
  password: string;
}

export interface ISignInResponseBody {
  authStatus: AuthStatus;
}

export interface IVerifyEmailRequestBody {
  token: string;
}

export interface ISubmitTwoFactorCodeRequestBody {
  authCode: string;
}

export interface ISubmitTwoFactorCodeResponseBody {
  authStatus: AuthStatus;
}

import { CheckboxTreeNodes } from "@/components/CheckboxTree";

export const filterNodes = (nodes: CheckboxTreeNodes, searchTerm: string) => {
  const processedSearchTerm = searchTerm.toLowerCase().trim();

  if (!processedSearchTerm) {
    return nodes;
  }

  const filteredNodes: CheckboxTreeNodes = nodes.reduce((accumulator, node) => {
    const isNodeLabelMatched = node.label
      .toLowerCase()
      .includes(processedSearchTerm);

    const filteredChildren: CheckboxTreeNodes = filterNodes(
      node.children || [],
      processedSearchTerm,
    );

    const shouldNodeBeIncluded =
      isNodeLabelMatched || !!filteredChildren.length;

    if (!shouldNodeBeIncluded) {
      return accumulator;
    }

    const clonedNode = {
      ...node,
    };

    delete clonedNode.children;

    if (filteredChildren.length) {
      clonedNode.children = filteredChildren;
    }

    return [...accumulator, clonedNode];
  }, [] as CheckboxTreeNodes);

  return filteredNodes;
};

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export interface ISortData<TSortKey> {
  column: TSortKey;
  order: SortOrder;
}

export interface IRequestDataWithSorting<TSortKey>
  extends Partial<ISortData<TSortKey>> {}

export interface IPaginatedResponseBody<TItems extends Array<unknown>> {
  items: TItems;
  pageIndex: number;
  pageSize: number;
  totalCount: number;
}

export interface IPaginatedRequestData {
  pageIndex?: number;
  pageSize?: number;
}

export interface IFilteredRequestData<TFilters> {
  filters?: TFilters;
}

export type Id = number;

import {
  FC,
  useCallback,
  useEffect,
  MouseEvent,
  ChangeEvent,
  useMemo,
} from "react";
import { Outlet, useParams } from "react-router-dom";
import { TransactionType } from "@/types/transaction";
import { PageContent } from "@/components/PageContent";
import { FeatureTitle } from "@/types/featureTitle";
import { TransactionsManagerTabs } from "@/features/transactionsManager/components/TransactionsManagerTabs";
import { TransactionDataGrid } from "@/features/transactionsManager/components/TransactionDataGrid";
import {
  useGetDepositTransactionsQuery,
  useGetDepositWithdrawalsQuery,
} from "@/features/transactionsManager/transactionsApi";
import { handleError } from "@/utils/handleError";
import {
  TransactionFiltersFormValues,
  transformValuesForBackend,
} from "@/features/transactionsManager/components/TransactionFilters";
import { useSearchParams } from "@/hooks/useSearchParams";
import { IRequestDataWithSorting } from "@/types/base";
import {
  IDepositTransaction,
  IGetDepositTransactionsRequestData,
  IGetWithdrawalTransactionsRequestData,
  IWithdrawalTransaction,
} from "@/features/transactionsManager/types";
import { useCacheBuster } from "@/hooks/useCacheBuster";
import * as Styled from "./TransactionsManagerPage.styles";

const DEFAULT_PAGE_INDEX = 1;
const DEFAULT_PAGE_SIZE = 10;

type TransactionsManagerPageParams = {
  transactionType: TransactionType;
};

interface ITransactionsManagerPageSearchParams
  extends IRequestDataWithSorting<
    keyof IWithdrawalTransaction & keyof IDepositTransaction
  > {
  filters?: TransactionFiltersFormValues;
  pageIndex?: number;
  pageSize?: number;
}

export const TransactionsManagerPage: FC = () => {
  const { cacheBuster, bustCache } = useCacheBuster();
  const { transactionType = TransactionType.Deposit } =
    useParams<TransactionsManagerPageParams>();
  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<ITransactionsManagerPageSearchParams>();

  const { pageIndex = DEFAULT_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE } =
    parsedSearchParams || {};

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPageIndex: number) => {
      mergeSearchParams({
        pageIndex: newPageIndex + 1,
      });
    },
    [mergeSearchParams],
  );

  const handlePageSizeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      mergeSearchParams({
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: Number(event?.target?.value),
      });
    },
    [mergeSearchParams],
  );

  const queryRequestData = useMemo<
    IGetDepositTransactionsRequestData & IGetWithdrawalTransactionsRequestData
  >(() => {
    const { pageIndex, pageSize, filters, column, order } =
      parsedSearchParams || {};

    return {
      pageIndex,
      pageSize,
      filters: filters && transformValuesForBackend(filters),
      column,
      order,
      cacheBuster,
    };
  }, [parsedSearchParams, cacheBuster]);

  const {
    data: depositTransactions,
    isFetching: isFetchingDepositTransactions,
    error: depositTransactionsError,
  } = useGetDepositTransactionsQuery(queryRequestData, {
    skip: transactionType !== TransactionType.Deposit,
  });
  const {
    data: withdrawalTransactions,
    isFetching: isFetchingWithdrawalTransactions,
    error: withdrawalTransactionsError,
  } = useGetDepositWithdrawalsQuery(queryRequestData, {
    skip: transactionType !== TransactionType.Withdrawal,
  });

  const dataByTransactionType = {
    [TransactionType.Deposit]: depositTransactions,
    [TransactionType.Withdrawal]: withdrawalTransactions,
  };

  const isFetchingByTransactionType = {
    [TransactionType.Deposit]: isFetchingDepositTransactions,
    [TransactionType.Withdrawal]: isFetchingWithdrawalTransactions,
  };

  const errorByTransactionType = {
    [TransactionType.Deposit]: depositTransactionsError,
    [TransactionType.Withdrawal]: withdrawalTransactionsError,
  };

  const data = dataByTransactionType[transactionType];
  const isFetching = isFetchingByTransactionType[transactionType];
  const error = errorByTransactionType[transactionType];

  const enforceReFetch = useCallback(() => {
    bustCache();
  }, [bustCache]);

  const handleFiltersChange = useCallback(
    (filters?: TransactionFiltersFormValues) => {
      mergeSearchParams({
        filters,
        pageIndex: undefined,
      });

      enforceReFetch();
    },
    [mergeSearchParams, enforceReFetch],
  );

  const handleClearAllFilters = useCallback(() => {
    handleFiltersChange();
  }, [handleFiltersChange]);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  return (
    <PageContent title={FeatureTitle.TransactionManager}>
      <TransactionsManagerTabs value={transactionType} />
      <Styled.TransactionFilters
        transactionType={transactionType}
        defaultValues={parsedSearchParams.filters}
        onClearAllClick={handleClearAllFilters}
        onApply={handleFiltersChange}
      />
      <TransactionDataGrid
        transactionType={transactionType}
        isFetching={isFetching}
        items={data?.items || []}
        requestData={queryRequestData}
      />
      <Styled.TransactionPagination
        count={data?.totalCount || 0}
        page={pageIndex - 1}
        onPageChange={handlePageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handlePageSizeChange}
      />
      <Outlet />
    </PageContent>
  );
};

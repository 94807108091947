import { FC, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";
import { Button } from "@/components/Button";
import { useLazyResendVerificationEmailQuery } from "@/features/auth/auth.api";
import { handleError } from "@/utils/handleError";

interface IEmailVerificationDialogProps extends DialogProps {
  onClose: () => void;
}

export const EmailVerificationDialog: FC<IEmailVerificationDialogProps> = ({
  onClose,
  ...restProps
}) => {
  const [resendVerificationEmail, { isFetching }] =
    useLazyResendVerificationEmailQuery();

  const handleResendVerificationEmail = useCallback(async () => {
    try {
      await resendVerificationEmail().unwrap();

      toast.success(
        "An email with a new verification link was successfully sent.",
      );
      onClose();
    } catch (error) {
      handleError(error);
    }
  }, [resendVerificationEmail, onClose]);

  return (
    <Dialog onClose={onClose} {...restProps}>
      <DialogTitle>Email verification</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your email address is not verified. Please use the verification link
          provided previously to your email or contact your administrator.&nbsp;
          <strong>You may also request a new verification link.</strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button isLoading={isFetching} onClick={handleResendVerificationEmail}>
          Resend verification link
        </Button>
      </DialogActions>
    </Dialog>
  );
};

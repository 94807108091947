import { useMemo } from "react";
import { FieldValues } from "react-hook-form";
import {
  FormSelectField,
  FormSelectFieldProps,
  FormSelectFieldOptions,
} from "@/components/form/FormSelectField";
import { UserRole } from "@/types/user";
import { useUser } from "@/hooks/useUser";

const userRoleLabelMap: Record<UserRole, string> = {
  [UserRole.Admin]: "Admin",
  [UserRole.Operator]: "Operator",
  [UserRole.Merchant]: "Merchant",
};

const allowedRolesByCurrentUserRole: Record<UserRole, UserRole[]> = {
  [UserRole.Admin]: [UserRole.Admin, UserRole.Operator, UserRole.Merchant],
  [UserRole.Operator]: [UserRole.Merchant],
  [UserRole.Merchant]: [],
};

export const UserRoleField = <TFormValues extends FieldValues>({
  label = "Role",
  ...restProps
}: Omit<FormSelectFieldProps<TFormValues>, "options">) => {
  const { user } = useUser();

  const options: FormSelectFieldOptions<UserRole> = useMemo(() => {
    if (!user?.role) {
      return [];
    }

    const allowedRoles = allowedRolesByCurrentUserRole[user.role];

    return allowedRoles.map((role) => ({
      value: role,
      label: userRoleLabelMap[role],
    }));
  }, [user?.role]);

  return <FormSelectField label={label} options={options} {...restProps} />;
};

import { api } from "@/store/api";
import {
  IGetEnumsResponseBody,
  IGetFileRequestBody,
} from "@/features/app/types";

const API_PREFIX = "/app";

const appApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEnums: builder.query<IGetEnumsResponseBody, void>({
      query: (params) => ({
        url: `${API_PREFIX}/enums`,
      }),
    }),
    getFile: builder.query<Blob, IGetFileRequestBody>({
      query: ({ token }) => ({
        url: `file/file/${token}`,
        responseHandler: (response) => response?.blob(),
      }),
    }),
  }),
  overrideExisting: false,
});
export const { useGetEnumsQuery, useLazyGetFileQuery } = appApi;

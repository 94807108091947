import { FC, useCallback, useEffect, useId, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  Verified as VerifiedIcon,
} from "@mui/icons-material";
import ipRegex from "ip-regex";
import { DefaultValues, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { handleError } from "@/utils/handleError";
import { Button } from "@/components/Button";
import {
  FormIpAddressField,
  FormTextField,
  MerchantField,
  MerchantsField,
  OperatorField,
  OperatorsAndMerchantsField,
  UserAuthTypeField,
  UserRoleField,
  UserStatusField,
} from "@/components/form";
import { UserAuthType, UserRole } from "@/types/user";
import { UserStatus } from "@/features/users/types";
import { ROUTES } from "@/routing/constants";
import { Operators } from "@/types/operator";
import { Merchants } from "@/types/merchant";
import { useGetOperatorsQuery } from "@/features/operators/operatorsApi";
import { useLazyGetMerchantsQuery } from "@/features/merchants/merchantsApi";
import { useUser } from "@/hooks/useUser";
import * as Styled from "./UserFormDialog.styles";

export enum FieldName {
  Email = "email",
  FullName = "fullName",
  AuthType = "authType",
  Status = "status",
  Role = "role",
  OperatorsAndMerchantsBlackList = "operatorsAndMerchantsBlackList",
  MerchantIdBlackList = "merchantIdBlackList",
  OperatorId = "operatorId",
  MerchantId = "merchantId",
  IpWhiteList = "ipWhiteList",
}

interface IUserFormSchemaContext {
  operators: Operators;
  merchants: Merchants;
}

const userFormSchema = yup
  .object({
    [FieldName.Email]: yup
      .string()
      .required()
      .email("Please double-check if the email is correct.")
      .trim()
      .label("Email"),
    [FieldName.FullName]: yup.string().required().trim().label("Full name"),
    [FieldName.AuthType]: yup
      .mixed<UserAuthType>()
      .required()
      .oneOf(Object.values(UserAuthType))
      .label("Auth type"),
    [FieldName.Status]: yup
      .mixed<UserStatus>()
      .required()
      .oneOf(Object.values(UserStatus))
      .label("Status"),
    [FieldName.Role]: yup
      .mixed<UserRole>()
      .required()
      .oneOf(Object.values(UserRole))
      .label("Role"),
    [FieldName.OperatorsAndMerchantsBlackList]: yup
      .object<IUserFormSchemaContext>()
      .shape({
        operatorIds: yup.array().of(yup.number().required()),
        merchantIds: yup.array().of(yup.number().required()),
      })
      .test(
        "operators-and-merchants-black-list",
        ({ operatorIds, merchantIds }, { parent, options, createError }) => {
          if (parent.role !== UserRole.Admin) {
            return true;
          }

          const { operators = [] } = options.context || {};

          if (operators?.length === operatorIds?.length) {
            return createError({
              message: "At least one operator or merchant should be selected.",
            });
          }

          return true;
        },
      ),
    [FieldName.MerchantIdBlackList]: yup
      .array()
      .of(yup.number().required())
      .test(
        "merchants-black-list",
        (merchantIdBlackList, { parent, options, createError }) => {
          if (parent.role !== UserRole.Operator) {
            return true;
          }

          const { merchants = [] } = options.context || {};

          if (merchants?.length === merchantIdBlackList?.length) {
            return createError({
              message: "At least one merchants should be selected.",
            });
          }

          return true;
        },
      ),
    [FieldName.OperatorId]: yup
      .number()
      .nullable()
      .when(FieldName.Role, {
        is: UserRole.Operator,
        then: (schema) => schema.required(),
      })
      .label("Operator"),
    [FieldName.MerchantId]: yup
      .number()
      .nullable()
      .when(FieldName.Role, {
        is: UserRole.Merchant,
        then: (schema) => schema.required(),
      })
      .label("Merchant"),
    [FieldName.IpWhiteList]: yup.array().of(
      yup
        .object({
          value: yup
            .string()
            .required()
            .test("ip-v4-validation", (value, { createError }) => {
              const isValid = ipRegex.v4({ exact: true }).test(value);

              if (isValid) {
                return true;
              }

              return createError({
                message: "Please provide a valid IPv4 address.",
              });
            })
            .label("IP Address"),
        })
        .required(),
    ),
  })
  .required();

export type UserFormValues = yup.InferType<typeof userFormSchema>;

export type UserFormDefaultValues = DefaultValues<UserFormValues>;

interface IUserFormDialogProps extends Omit<DialogProps, "onSubmit"> {
  title: string;
  submitButtonText: string;
  defaultValues: UserFormDefaultValues;
  isEmailVerifiedBadgeVisible?: boolean;
  isSubmitting?: boolean;
  disabledFields?: FieldName[];
  onSubmit: (values: UserFormValues) => void;
}

export const UserFormDialog: FC<IUserFormDialogProps> = ({
  title,
  submitButtonText,
  defaultValues,
  isEmailVerifiedBadgeVisible,
  isSubmitting,
  disabledFields,
  children,
  onSubmit,
  ...restProps
}) => {
  const { user: currentUser } = useUser();
  const formId = useId();

  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: operatorsData,
    isFetching: isFetchingOperators,
    error: operatorsFetchError,
  } = useGetOperatorsQuery(undefined, {
    skip: currentUser?.role !== UserRole.Admin,
  });
  const { items: operators = [] } = operatorsData || {};
  const [
    getMerchants,
    {
      data: merchantsData,
      isFetching: isFetchingMerchants,
      error: merchantsFetchError,
    },
  ] = useLazyGetMerchantsQuery();
  const { items: merchants = [] } = merchantsData || {};

  const formContext = useMemo<IUserFormSchemaContext>(
    () => ({
      operators,
      merchants,
    }),
    [operators, merchants],
  );

  const { control, handleSubmit, watch, reset, setValue } = useForm<
    UserFormValues,
    IUserFormSchemaContext
  >({
    resolver: yupResolver<UserFormValues>(userFormSchema),
    mode: "onBlur",
    defaultValues,
    context: formContext,
    shouldUnregister: true,
  });

  const {
    fields: ipWhiteListFields,
    append: appendIpWhiteList,
    remove: removeIpWhiteList,
  } = useFieldArray({
    name: FieldName.IpWhiteList,
    control,
  });

  const roleValue = watch(FieldName.Role);
  const operatorIdValue = watch(FieldName.OperatorId);

  const isFieldDisabled = useCallback(
    (fieldName: FieldName) => {
      if (isSubmitting) {
        return true;
      }

      return !!disabledFields?.includes(fieldName);
    },
    [isSubmitting, disabledFields],
  );

  const handleClose = useCallback(() => {
    navigate(ROUTES.users.createURL(location.search));
  }, [navigate, location.search]);

  const handleFormSubmit = useCallback(
    async (values: UserFormValues) => {
      try {
        await onSubmit(values);
        handleClose();
      } catch (error) {
        handleError(error);
      }
    },
    [onSubmit, handleClose],
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (roleValue === UserRole.Merchant) {
      setValue(FieldName.Status, UserStatus.Inactive);
    }
  }, [roleValue, setValue]);

  useEffect(() => {
    getMerchants({
      filters: {
        operatorId: operatorIdValue,
      },
    });
  }, [operatorIdValue, getMerchants]);

  useEffect(() => {
    if (operatorsFetchError) {
      handleError(
        operatorsFetchError,
        "Something went wrong during operators fetching. Please try again.",
      );
    }
  }, [operatorsFetchError]);

  useEffect(() => {
    if (merchantsFetchError) {
      handleError(
        merchantsFetchError,
        "Something went wrong during merchants fetching. Please try again.",
      );
    }
  }, [merchantsFetchError]);

  return (
    <Dialog fullWidth disableRestoreFocus {...restProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
          <Styled.Fields>
            <FormTextField
              name={FieldName.Email}
              control={control}
              disabled={isFieldDisabled(FieldName.Email)}
              label="Email"
              autoFocus
              InputProps={
                isEmailVerifiedBadgeVisible
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <VerifiedIcon />
                        </InputAdornment>
                      ),
                    }
                  : undefined
              }
            />
            <FormTextField
              name={FieldName.FullName}
              control={control}
              disabled={isFieldDisabled(FieldName.FullName)}
              label="Full name"
            />
            <UserAuthTypeField
              name={FieldName.AuthType}
              control={control}
              disabled={isFieldDisabled(FieldName.AuthType)}
            />
            <UserStatusField
              name={FieldName.Status}
              control={control}
              disabled={
                isFieldDisabled(FieldName.Status) ||
                roleValue === UserRole.Merchant
              }
            />
            <UserRoleField
              name={FieldName.Role}
              control={control}
              disabled={isFieldDisabled(FieldName.Role)}
            />
            {roleValue === UserRole.Admin && (
              <OperatorsAndMerchantsField
                name={FieldName.OperatorsAndMerchantsBlackList}
                control={control}
                disabled={
                  isFieldDisabled(FieldName.OperatorsAndMerchantsBlackList) ||
                  isFetchingOperators
                }
                operators={operators}
                withInverse
              />
            )}
            {roleValue === UserRole.Operator && (
              <>
                <OperatorField
                  name={FieldName.OperatorId}
                  control={control}
                  operators={operators}
                  disabled={
                    isFieldDisabled(FieldName.OperatorId) || isFetchingOperators
                  }
                />
                {operatorIdValue && (
                  <MerchantsField
                    name={FieldName.MerchantIdBlackList}
                    control={control}
                    merchants={merchants}
                    disabled={
                      isFieldDisabled(FieldName.MerchantIdBlackList) ||
                      isFetchingMerchants
                    }
                    withInverse
                  />
                )}
              </>
            )}
            {roleValue === UserRole.Merchant && (
              <MerchantField
                name={FieldName.MerchantId}
                control={control}
                merchants={merchants}
                disabled={
                  isFieldDisabled(FieldName.MerchantId) || isFetchingMerchants
                }
              />
            )}
          </Styled.Fields>
          <Styled.Divider />
          <Accordion square disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Allowed IP addresses
            </AccordionSummary>
            <AccordionDetails>
              <Styled.Fields>
                {ipWhiteListFields.map((field, index) => (
                  <FormIpAddressField
                    key={field.id}
                    name={`${FieldName.IpWhiteList}.${index}.value` as const}
                    control={control}
                    disabled={isSubmitting}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => removeIpWhiteList(index)}>
                          <DeleteIcon />
                        </IconButton>
                      ),
                    }}
                  />
                ))}
              </Styled.Fields>
            </AccordionDetails>
            <AccordionActions>
              <Button onClick={() => appendIpWhiteList({ value: "" })}>
                Add IP address
              </Button>
            </AccordionActions>
          </Accordion>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" disabled={isSubmitting} onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" form={formId} isLoading={isSubmitting}>
          {submitButtonText}
        </Button>
      </DialogActions>
      {children}
    </Dialog>
  );
};

import { DateRange } from "@/features/transactionsManager/components/TransactionFilters/types";
import {
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
  subDays,
  subWeeks,
} from "date-fns";

interface IDateRangeValues {
  fromDate: Date;
  toDate: Date;
}

export const getDateRangeValues = (dateRange: DateRange) => {
  const todayStartOfTheDay = startOfDay(new Date());
  const todayEndOfTheDay = endOfDay(new Date());

  const getValuesMap: Record<DateRange, () => IDateRangeValues | null> = {
    [DateRange.Today]: () => ({
      fromDate: todayStartOfTheDay,
      toDate: todayEndOfTheDay,
    }),
    [DateRange.Yesterday]: () => ({
      fromDate: subDays(todayStartOfTheDay, 1),
      toDate: subDays(todayEndOfTheDay, 1),
    }),
    [DateRange.CurrentWeek]: () => ({
      fromDate: startOfISOWeek(todayStartOfTheDay),
      toDate: endOfISOWeek(todayEndOfTheDay),
    }),
    [DateRange.CurrentMonth]: () => ({
      fromDate: startOfMonth(todayStartOfTheDay),
      toDate: endOfMonth(todayEndOfTheDay),
    }),
    [DateRange.LastWeek]: () => ({
      fromDate: startOfISOWeek(subWeeks(todayStartOfTheDay, 1)),
      toDate: endOfISOWeek(subWeeks(todayEndOfTheDay, 1)),
    }),
    [DateRange.Last30Days]: () => ({
      fromDate: subDays(todayStartOfTheDay, 29),
      toDate: todayEndOfTheDay,
    }),
    [DateRange.Last180Days]: () => ({
      fromDate: subDays(todayStartOfTheDay, 179),
      toDate: todayEndOfTheDay,
    }),
    [DateRange.LastYear]: () => ({
      fromDate: startOfYear(todayStartOfTheDay),
      toDate: endOfYear(todayEndOfTheDay),
    }),
    [DateRange.CustomPeriod]: () => null,
  };

  return getValuesMap[dateRange]();
};

import { useCallback, useState } from "react";
import { FieldValues } from "react-hook-form";
import { VisibilitySharp, VisibilityOffSharp } from "@mui/icons-material";
import { FormTextField, FormTextFieldProps } from "../FormTextField";
import * as Styled from "./FormPasswordField.styles";

export const FormPasswordField = <TFormValues extends FieldValues>({
  name,
  control,
  label = "Password",
  InputProps,
  ...restProps
}: FormTextFieldProps<TFormValues>) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleSwitchPasswordVisibility = useCallback(() => {
    setIsPasswordVisible((prevValue) => !prevValue);
  }, []);

  return (
    <FormTextField
      name={name}
      control={control}
      label={label}
      type={isPasswordVisible ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <Styled.PasswordVisibilityButton
            onClick={handleSwitchPasswordVisibility}
          >
            {isPasswordVisible ? <VisibilitySharp /> : <VisibilityOffSharp />}
          </Styled.PasswordVisibilityButton>
        ),
        ...InputProps,
      }}
      {...restProps}
    />
  );
};

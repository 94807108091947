import { ReactNode, useCallback } from "react";
import { ISortData, SortOrder } from "@/types/base";
import { EMPTY_VALUE_FALLBACK } from "@/constants/common";
import {
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { TableEmptyMessage } from "@/components/TableEmptyMessage";
import { dataGridClasses } from "./constants";
import {
  IUseDataGridScrollData,
  useDataGridScroll,
} from "./hooks/useDataGridScroll";
import {
  IDataGridColumn,
  IDataGridColumns,
  IDataGridLoadingData,
  IDataGridPinnedColumns,
} from "./types";
import * as Styled from "./DataGrid.styles";

const DEFAULT_SKELETON_ROWS_COUNT = 5;

export interface IDataGridProps<TEntity, TSortKey = string> {
  className?: string;
  columns: IDataGridColumns<TEntity, TSortKey>;
  data: TEntity[];
  emptyMessage: ReactNode;
  sortData?: ISortData<TSortKey>;
  tableFooter?: ReactNode;
  loading?: IDataGridLoadingData;
  pinnedColumns?: IDataGridPinnedColumns;
  size?: TableProps["size"];
  getRowId?: (rowData: TEntity) => string | number;
  getRowClassName?: (rowData: TEntity) => string;
  onSortChange?: (sortData?: ISortData<TSortKey>) => void;
  onScroll?: IUseDataGridScrollData["onScroll"];
}

type IDefaultEntityType = Record<string, any>;

const getDefaultRowId = (rowData: IDefaultEntityType) => rowData.id;

export const DataGrid = <TEntity extends IDefaultEntityType, TSortKey>({
  className,
  columns,
  data,
  sortData,
  emptyMessage,
  tableFooter,
  loading,
  pinnedColumns,
  size = "small",
  getRowId = getDefaultRowId,
  getRowClassName,
  onSortChange,
  onScroll,
}: IDataGridProps<TEntity, TSortKey>) => {
  const { column: sortColumn, order: sortOrder } = sortData || {};

  const { tableContainerRef, scrollData, handleScrollChange } =
    useDataGridScroll({ onScroll });

  const {
    isLoadingTable = false,
    skeletonRowsCount = DEFAULT_SKELETON_ROWS_COUNT,
    skeletonProps,
  } = loading || {};

  const shouldShowEmptyMessage = data.length === 0 && !isLoadingTable;

  const createSortHandler = useCallback(
    (sortKey: TSortKey) => () => {
      if (!onSortChange) {
        return;
      }

      switch (true) {
        case sortColumn !== sortKey:
          return onSortChange({ column: sortKey, order: SortOrder.Asc });
        case sortOrder === SortOrder.Asc:
          return onSortChange({ column: sortKey, order: SortOrder.Desc });
        default:
          onSortChange();
      }
    },
    [sortColumn, sortOrder, onSortChange],
  );

  const handleRenderHeaderRowCell = useCallback(
    (column: IDataGridColumn<TEntity, TSortKey>) => {
      const { headerName, sortKey } = column;

      if (!sortKey) {
        return headerName;
      }

      return (
        <TableSortLabel
          active={sortColumn === sortKey}
          direction={sortOrder}
          onClick={createSortHandler(sortKey)}
        >
          {headerName}
        </TableSortLabel>
      );
    },
    [sortColumn, sortOrder, createSortHandler],
  );

  const handleRenderBodyRowCell = useCallback(
    (column: IDataGridColumn<TEntity, TSortKey>, row: TEntity) => {
      const { field, renderCell } = column;

      if (renderCell) {
        return renderCell({ rowData: row });
      }

      return row[field] || EMPTY_VALUE_FALLBACK;
    },
    [],
  );

  const handleRenderSkeletonLoadingRows = useCallback(() => {
    return Array.from(Array(skeletonRowsCount).keys()).map((index) => (
      <TableRow key={index}>
        {columns.map((column) => {
          const { field, headerName } = column;

          return (
            <TableCell key={field + headerName}>
              <Skeleton {...skeletonProps} />
            </TableCell>
          );
        })}
      </TableRow>
    ));
  }, [skeletonRowsCount, skeletonProps, columns]);

  const getCellClassName = useCallback(
    (field: string) => {
      switch (true) {
        case pinnedColumns?.left?.includes(field):
          return dataGridClasses.cellStickyLeft;
        case pinnedColumns?.right?.includes(field):
          return dataGridClasses.cellStickyRight;
        default:
          return undefined;
      }
    },
    [pinnedColumns],
  );

  return (
    <Styled.TableContainer
      className={className}
      ref={tableContainerRef}
      $isFullyScrolledToLeft={scrollData.edgeOffset.left <= 0}
      $isFullyScrolledToRight={scrollData.edgeOffset.right <= 0}
      onScroll={handleScrollChange}
    >
      <Styled.Table stickyHeader size={size}>
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              const { field, headerName, sortKey, headCellSx } = column;

              return (
                <Styled.HeadCell
                  key={field + headerName}
                  className={getCellClassName(field)}
                  variant="head"
                  sortDirection={sortColumn === sortKey && sortOrder}
                  sx={headCellSx}
                >
                  {handleRenderHeaderRowCell(column)}
                </Styled.HeadCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingTable
            ? handleRenderSkeletonLoadingRows()
            : data.map((row, index) => {
                const rowClassName = getRowClassName
                  ? getRowClassName(row)
                  : undefined;

                return (
                  <TableRow
                    className={rowClassName}
                    key={getRowId(row) || index}
                    hover
                  >
                    {columns.map((column) => {
                      const { field, headerName, bodyCellSx } = column;

                      return (
                        <TableCell
                          key={field + headerName}
                          className={getCellClassName(field)}
                          sx={bodyCellSx}
                        >
                          {handleRenderBodyRowCell(column, row)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
        </TableBody>
        <caption className={dataGridClasses.caption}>
          {shouldShowEmptyMessage && (
            <TableEmptyMessage>{emptyMessage}</TableEmptyMessage>
          )}
          {tableFooter}
        </caption>
      </Styled.Table>
    </Styled.TableContainer>
  );
};

import { FC } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AppLayout } from "@/components/AppLayout";
import { NoMatch } from "@/components/NoMatch";
import { SignInPage } from "@/features/auth/pages/SignInPage";
import { EmailVerificationPage } from "@/features/auth/pages/EmailVerificationPage";
import { DashboardPage } from "@/features/dashboard/pages";
import { TransactionsManagerPage } from "@/features/transactionsManager/pages/TransactionsManagerPage";
import { UsersPage } from "@/features/users/pages/UsersPage";
import { UserCreateDialog } from "@/features/users/components/UserCreateDialog";
import { UserEditDialog } from "@/features/users/components/UserEditDialog";
import { AuthGuard } from "@/routing/AuthGuard";
import { PermissionsRestrictedRoute } from "@/routing/PermissionsRestrictedRoute";
import { ROUTES } from "./constants";
import { TransactionDetailsDialog } from "@/features/transactionsManager/components/TransactionDetailsDialog";

const DEFAULT_PATH = ROUTES.transactionsManager.createURL();

export const Router: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path={ROUTES.signIn.path} element={<SignInPage />} />
      <Route
        path={ROUTES.emailVerification.path}
        element={<EmailVerificationPage />}
      />
      <Route
        path="/"
        element={
          <AuthGuard>
            <AppLayout />
          </AuthGuard>
        }
      >
        <Route index element={<Navigate to={DEFAULT_PATH} />} />
        <Route
          element={
            <PermissionsRestrictedRoute predicate={({ users }) => users} />
          }
        >
          <Route path={ROUTES.dashboard.path} element={<DashboardPage />} />
          <Route path={ROUTES.users.path} element={<UsersPage />}>
            <Route
              path={ROUTES.usersCreate.path}
              element={<UserCreateDialog open={true} />}
            />
            <Route
              path={ROUTES.usersEdit.path}
              element={<UserEditDialog open={true} />}
            />
          </Route>
        </Route>
        <Route
          path={ROUTES.transactionsManager.path}
          element={<TransactionsManagerPage />}
        >
          <Route
            path={ROUTES.transactionsDetails.path}
            element={<TransactionDetailsDialog open={true} />}
          />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

import { FC, SyntheticEvent, useCallback, useMemo, useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from "@mui/material";
import {
  CheckboxTree,
  CheckboxTreeNodes,
  CheckboxTreeValues,
  ICheckboxTreeProps,
} from "@/components/CheckboxTree";
import { SearchTextField } from "@/components/SearchTextField";
import {
  getSelectValue,
  filterNodes,
} from "@/components/CheckboxTreeSelect/utils";
import * as Styled from "./CheckboxTreeSelect.styles";

export type { CheckboxTreeNodes, CheckboxTreeValues };

export interface ICheckboxTreeSelectProps
  extends Omit<
      SelectProps,
      "options" | "value" | "defaultValue" | "multiple" | "onChange"
    >,
    Pick<
      ICheckboxTreeProps,
      "nodes" | "checkedValues" | "withInverse" | "onChange"
    > {
  label?: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
}

const renderValue = (selected: CheckboxTreeValues) => {
  return selected.join(", ");
};

export const CheckboxTreeSelect: FC<ICheckboxTreeSelectProps> = ({
  className,
  label,
  nodes,
  checkedValues,
  disabled,
  withInverse,
  helperText,
  error,
  size,
  onChange,
  onClose,
  ...restProps
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredNodes = useMemo(() => {
    return filterNodes(nodes, searchTerm);
  }, [nodes, searchTerm]);

  const selectValue = useMemo(() => {
    return getSelectValue(nodes, checkedValues, withInverse);
  }, [nodes, checkedValues, withInverse]);

  const handleClose = useCallback(
    (event: SyntheticEvent<Element, Event>) => {
      setSearchTerm("");

      if (onClose) {
        onClose(event);
      }
    },
    [onClose],
  );

  return (
    <FormControl className={className} error={error} size={size}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={selectValue}
        label={label}
        disabled={disabled}
        multiple
        renderValue={renderValue}
        onClose={handleClose}
        {...restProps}
      >
        <Styled.Dropdown>
          <SearchTextField value={searchTerm} onChange={setSearchTerm} />
          <CheckboxTree
            checkedValues={checkedValues}
            nodes={filteredNodes}
            disabled={disabled}
            withInverse={withInverse}
            withSelectAll
            onChange={onChange}
          />
        </Styled.Dropdown>
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

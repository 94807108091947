import { useCallback, useMemo } from "react";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISortData } from "@/types/base";

export const useSort = <TSortKey = string>() => {
  type SortData = ISortData<TSortKey>;

  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<Partial<SortData>>();

  const sortData = useMemo<SortData | undefined>(() => {
    const { column, order } = parsedSearchParams;

    if (!column || !order) {
      return undefined;
    }

    return {
      column,
      order,
    };
  }, [parsedSearchParams]);

  const handleChangeSortData = useCallback(
    (data?: SortData) => {
      const { column, order } = data || {};

      mergeSearchParams({
        column,
        order,
      });
    },
    [mergeSearchParams],
  );

  return {
    sortData,
    handleChangeSortData,
  };
};

import { useEffect, useMemo } from "react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { handleError } from "@/utils/handleError";
import { TransactionType } from "@/types/transaction";
import {
  useLazyGetDepositTransactionsFiltersQuery,
  useLazyGetWithdrawalTransactionsFiltersQuery,
} from "@/features/transactionsManager/transactionsApi";
import { useGetEnumsQuery } from "@/features/app/appApiSlice";
import { FormSelectFieldOptions } from "@/components/form";

interface IUseTransactionFiltersOptions {
  transactionType: TransactionType;
}

const convertArrayToSelectOptions = (
  items: string[],
): FormSelectFieldOptions => {
  return items.map((item) => ({
    label: item,
    value: item,
  }));
};

export const useTransactionFilters = ({
  transactionType,
}: IUseTransactionFiltersOptions) => {
  const {
    data: enumsData,
    isFetching: isFetchingEnums,
    error: enumsFetchError,
  } = useGetEnumsQuery();
  const [
    getDepositFilters,
    {
      data: depositFiltersData,
      isFetching: isFetchingDepositFilters,
      error: depositFiltersError,
    },
  ] = useLazyGetDepositTransactionsFiltersQuery();
  const [
    getWithdrawalFilters,
    {
      data: withdrawalFiltersData,
      isFetching: isFetchingWithdrawalFilters,
      error: withdrawalFiltersError,
    },
  ] = useLazyGetWithdrawalTransactionsFiltersQuery();

  const {
    utcOffset: utcOffsetOptions = [],
    depositFilterDateType = [],
    withdrawalFilterDateType = [],
    depositKeywordType = [],
    withdrawalKeywordType = [],
  } = enumsData || {};

  const dateTypeOptionsByTransactionType: Record<
    TransactionType,
    FormSelectFieldOptions
  > = {
    [TransactionType.Deposit]: depositFilterDateType,
    [TransactionType.Withdrawal]: withdrawalFilterDateType,
  };
  const keywordOptionsByTransactionType: Record<
    TransactionType,
    FormSelectFieldOptions
  > = {
    [TransactionType.Deposit]: depositKeywordType,
    [TransactionType.Withdrawal]: withdrawalKeywordType,
  };

  const queryByTransactionType: Record<
    TransactionType,
    typeof getDepositFilters | typeof getWithdrawalFilters
  > = {
    [TransactionType.Deposit]: getDepositFilters,
    [TransactionType.Withdrawal]: getWithdrawalFilters,
  };
  const dataByTransactionType: Record<
    TransactionType,
    typeof depositFiltersData | typeof withdrawalFiltersData
  > = {
    [TransactionType.Deposit]: depositFiltersData,
    [TransactionType.Withdrawal]: withdrawalFiltersData,
  };
  const isFetchingByTransactionType: Record<TransactionType, boolean> = {
    [TransactionType.Deposit]: isFetchingDepositFilters,
    [TransactionType.Withdrawal]: isFetchingWithdrawalFilters,
  };
  const errorByTransactionType: Record<
    TransactionType,
    FetchBaseQueryError | SerializedError | undefined
  > = {
    [TransactionType.Deposit]: depositFiltersError,
    [TransactionType.Withdrawal]: withdrawalFiltersError,
  };

  const query = queryByTransactionType[transactionType];
  const data = dataByTransactionType[transactionType];
  const isFetching = isFetchingByTransactionType[transactionType];
  const error = errorByTransactionType[transactionType];

  const dateTypeOptions = dateTypeOptionsByTransactionType[transactionType];
  const keywordOptions = keywordOptionsByTransactionType[transactionType];
  const { isLiveFilter, operators = [], merchants = [] } = data || {};

  const {
    ppaNamesOptions,
    providersOptions,
    paymentMethodsOptions,
    statusesOptions,
    merchantCurrencyOptions,
    midCurrencyOptions,
  } = useMemo(
    () => ({
      ppaNamesOptions: convertArrayToSelectOptions(data?.ppaNames || []),
      providersOptions: convertArrayToSelectOptions(data?.providers || []),
      paymentMethodsOptions: convertArrayToSelectOptions(
        data?.paymentMethods || [],
      ),
      statusesOptions: convertArrayToSelectOptions(data?.statuses || []),
      merchantCurrencyOptions: convertArrayToSelectOptions(
        data?.merchantCurrencies || [],
      ),
      midCurrencyOptions: convertArrayToSelectOptions(
        data?.midCurrencies || [],
      ),
    }),
    [data],
  );

  useEffect(() => {
    if (enumsFetchError) {
      handleError(
        enumsFetchError,
        "Whoops! An error happen while fetching transaction filters enums. Please try again later.",
      );
    }
  }, [enumsFetchError]);

  useEffect(() => {
    if (error) {
      handleError(
        error,
        "Whoops! An error happen while fetching transaction filters. Please try again later.",
      );
    }
  }, [error]);

  return {
    isFetching: isFetching || isFetchingEnums,
    isLiveFilter,
    operators,
    merchants,
    dateTypeOptions,
    utcOffsetOptions,
    ppaNamesOptions,
    providersOptions,
    paymentMethodsOptions,
    statusesOptions,
    merchantCurrencyOptions,
    midCurrencyOptions,
    keywordOptions,
    getFiltersData: query,
  };
};

import { FC } from "react";
import { IUser, Users } from "@/features/users/types";
import { useSort } from "@/hooks/useSort";
import { DataGrid, IDataGridColumns } from "@/components/DataGrid";
import { formatDate } from "@/utils/dataFormat/formatDate";
import { UserActionsButton } from "@/features/users/components/UserActionsButton";

enum UsersDataGridField {
  Email = "email",
  FullName = "fullName",
  Role = "role",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  Status = "status",
}

const columns: IDataGridColumns<IUser, UsersDataGridField> = [
  {
    field: UsersDataGridField.Email,
    headerName: "Email",
    sortKey: UsersDataGridField.Email,
    headCellSx: {
      minWidth: 240,
    },
  },
  {
    field: UsersDataGridField.FullName,
    headerName: "Name",
    sortKey: UsersDataGridField.FullName,
    headCellSx: {
      minWidth: 200,
    },
  },
  {
    field: UsersDataGridField.Role,
    headerName: "Role",
    sortKey: UsersDataGridField.Role,
    headCellSx: {
      minWidth: 95,
    },
  },
  {
    field: UsersDataGridField.CreatedAt,
    headerName: "Created",
    sortKey: UsersDataGridField.CreatedAt,
    headCellSx: {
      minWidth: 215,
    },
    renderCell: ({ rowData }) => formatDate(rowData.createdAt),
  },
  {
    field: UsersDataGridField.UpdatedAt,
    headerName: "Updated",
    sortKey: UsersDataGridField.UpdatedAt,
    headCellSx: {
      minWidth: 215,
    },
    renderCell: ({ rowData }) => formatDate(rowData.updatedAt),
  },
  {
    field: UsersDataGridField.Status,
    headerName: "Status",
    sortKey: UsersDataGridField.Status,
    headCellSx: {
      minWidth: 110,
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    headCellSx: {
      width: "1px",
    },
    renderCell: ({ rowData }) => <UserActionsButton user={rowData} />,
  },
];

interface IUsersDataGridProps {
  className?: string;
  isFetching: boolean;
  items: Users;
}

export const UsersDataGrid: FC<IUsersDataGridProps> = ({
  className,
  isFetching,
  items,
}) => {
  const { sortData, handleChangeSortData } = useSort<UsersDataGridField>();

  return (
    <DataGrid
      className={className}
      columns={columns}
      data={items}
      emptyMessage="Whoops! There're no users at the moment :("
      loading={{
        isLoadingTable: isFetching,
      }}
      sortData={sortData}
      onSortChange={handleChangeSortData}
    />
  );
};

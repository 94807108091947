import { styled } from "@/styles/styled";
import { ArrowRight } from "@mui/icons-material";
import { TransactionStatus } from "@/features/transactionsManager/types";

export const Cell = styled("div")`
  display: flex;
`;

export const StatusIcon = styled(ArrowRight)<{ $status: TransactionStatus }>(({
  theme,
  $status,
}) => {
  const colorMap: Record<TransactionStatus, string> = {
    [TransactionStatus.Initiated]: theme.palette.common.white,
    [TransactionStatus.InProgress]: theme.palette.common.white,
    [TransactionStatus.Pending]: theme.palette.common.white,
    [TransactionStatus.Processing]: theme.palette.warning.main,
    [TransactionStatus.Confirmed]: theme.palette.success.main,
    [TransactionStatus.Failed]: theme.palette.error.main,
    [TransactionStatus.Cancelled]: theme.palette.error.main,
    [TransactionStatus.Declined]: theme.palette.error.main,
  };

  return {
    color: colorMap[$status],
  };
});

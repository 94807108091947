import { FC } from "react";
import { IUser } from "@/features/users/types";
import { useUserActions } from "@/features/users/components/UserActionsButton/useUserActions";
import { ActionsButton } from "@/components/ActionsButton";

interface IUserActionsButtonProps {
  user: IUser;
}

export const UserActionsButton: FC<IUserActionsButtonProps> = ({ user }) => {
  const { userActionsItems } = useUserActions({ user });

  return <ActionsButton items={userActionsItems} />;
};

import { FC } from "react";
import {
  BackdropProps,
  CircularProgress,
  CircularProgressProps,
} from "@mui/material";
import * as Styled from "./LoadingOverlay.styles";

interface ILoadingOverlayProps extends BackdropProps {
  circularProgressProps?: CircularProgressProps;
}

export const LoadingOverlay: FC<ILoadingOverlayProps> = ({
  circularProgressProps,
  ...restProps
}) => (
  <Styled.Backdrop {...restProps}>
    <CircularProgress size={70} thickness={1} {...circularProgressProps} />
  </Styled.Backdrop>
);

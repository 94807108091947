import { FC, ReactNode } from "react";
import * as Styled from "./TableEmptyMessage.styles";

interface ITableEmptyMessageProps {
  children: ReactNode;
}

export const TableEmptyMessage: FC<ITableEmptyMessageProps> = ({
  children,
}) => <Styled.EmptyMessage variant="body1">{children}</Styled.EmptyMessage>;

import { FC } from "react";
import { TransactionExportButton } from "@/features/transactionsManager/components/TransactionExportButton";
import { TransactionType } from "@/types/transaction";
import {
  IGetDepositTransactionsRequestData,
  IGetWithdrawalTransactionsRequestData,
} from "@/features/transactionsManager/types";
import { TransactionDataGridColumns } from "@/features/transactionsManager/components/TransactionDataGrid/useTransactionDataGridColumns";
import { TransactionDataGridField } from "@/features/transactionsManager/components/TransactionDataGrid/types";
import * as Styled from "./TransactionDataGridToolbar.styles";

interface ITransactionDataGridToolbarProps {
  transactionColumns: TransactionDataGridColumns;
  hiddenColumnFields: TransactionDataGridField[];
  transactionType: TransactionType;
  exportRequestData: IGetDepositTransactionsRequestData &
    IGetWithdrawalTransactionsRequestData;
  onVisibleColumnsChange: (
    data?: TransactionDataGridField[] | undefined,
  ) => void;
}

export const TransactionDataGridToolbar: FC<
  ITransactionDataGridToolbarProps
> = ({
  transactionColumns,
  hiddenColumnFields,
  onVisibleColumnsChange,
  transactionType,
  exportRequestData,
}) => {
  return (
    <Styled.Container>
      <Styled.EditColumnsField
        columns={transactionColumns}
        hiddenColumnFields={hiddenColumnFields}
        onChange={onVisibleColumnsChange}
      />
      <TransactionExportButton
        transactionType={transactionType}
        requestData={exportRequestData}
      />
    </Styled.Container>
  );
};

import { ReactElement } from "react";
import { Control, Controller, Path, FieldValues } from "react-hook-form";
import {
  DatePicker,
  DatePickerProps as MuiDatePickerProps,
} from "@mui/x-date-pickers";

export type DatePickerProps = MuiDatePickerProps<Date>;

export type FormDateFieldProps<TFormValues extends FieldValues> = Omit<
  DatePickerProps,
  "value" | "onChange"
> & {
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  helperText?: string;
};

export const FormDateField = <TFormValues extends FieldValues>({
  name,
  control,
  helperText,
  slotProps,
  ...restProps
}: FormDateFieldProps<TFormValues>): ReactElement => {
  const { textField: textFieldSlotProps, ...restSlotProps } = slotProps || {};

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { value, onBlur, onChange, ...restFieldProps } = field;

        return (
          <DatePicker
            value={value ? new Date(value) : null}
            closeOnSelect
            slotProps={{
              ...restSlotProps,
              textField: {
                error: !!error,
                helperText: error?.message || helperText,
                InputLabelProps: {
                  shrink: true,
                },
                onBlur,
                ...textFieldSlotProps,
              },
            }}
            disableFuture
            onChange={onChange}
            {...restFieldProps}
            {...restProps}
          />
        );
      }}
    />
  );
};

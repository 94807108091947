import { UserAuthType } from "@/types/user";

export const codeLocationHintByTwoFactorType: Partial<
  Record<UserAuthType, string>
> = {
  [UserAuthType.Email]:
    "A text message with a 6-digit verification code was just sent to your email.",
  [UserAuthType.GoogleAuthenticator]:
    "You can find your 6-digit verification code in the Google Authenticator app.",
};

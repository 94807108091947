import { ReactElement } from "react";
import { Control, Controller, Path, FieldValues } from "react-hook-form";
import {
  CheckboxTreeSelect,
  ICheckboxTreeSelectProps,
  CheckboxTreeValues,
  CheckboxTreeNodes,
} from "@/components/CheckboxTreeSelect";

export type { CheckboxTreeNodes, CheckboxTreeValues };

type TransformInput<TOutputValue> = (
  value?: TOutputValue,
) => CheckboxTreeValues;
type TransformOutput<TOutputValue> = (
  checkboxTreeValues: CheckboxTreeValues,
) => TOutputValue;

export type Transform<TOutputValue> = {
  input: TransformInput<TOutputValue>;
  output: TransformOutput<TOutputValue>;
};

export type FormCheckboxTreeSelectFieldProps<
  TFormValues extends FieldValues,
  TTransformOutput = any,
> = Omit<ICheckboxTreeSelectProps, "checkedValues" | "onChange"> & {
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  transform?: Transform<TTransformOutput>;
};

export const FormCheckboxTreeSelectField = <
  TFormValues extends FieldValues,
  TTransformOutput = any,
>({
  name,
  control,
  helperText,
  disabled,
  transform,
  ...restProps
}: FormCheckboxTreeSelectFieldProps<
  TFormValues,
  TTransformOutput
>): ReactElement => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => {
      const { value, onChange, ...restFieldProps } = field;

      return (
        <CheckboxTreeSelect
          checkedValues={(transform ? transform.input(value) : value) || []}
          error={!!error}
          helperText={error?.message || helperText}
          disabled={disabled}
          onChange={(value) => {
            transform ? onChange(transform.output(value)) : onChange(value);
          }}
          {...restFieldProps}
          {...restProps}
        />
      );
    }}
  />
);

import { ChangeEvent, FC, useCallback, KeyboardEvent } from "react";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { SearchSharp } from "@mui/icons-material";

type SearchTextFieldProps = Omit<TextFieldProps, "onChange"> & {
  onChange: (value: string) => void;
};

export const SearchTextField: FC<SearchTextFieldProps> = ({
  value,
  onChange,
  ...restProps
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      onChange(value);
    },
    [onChange],
  );

  // https://github.com/mui/material-ui/issues/19096
  const handlePreventKeyDownPropagation = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      event.stopPropagation();
    },
    [],
  );

  return (
    <TextField
      type="search"
      label="Search"
      size="small"
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchSharp />
          </InputAdornment>
        ),
      }}
      onChange={handleChange}
      onKeyDown={handlePreventKeyDownPropagation}
      {...restProps}
    />
  );
};

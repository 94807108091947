import { styled } from "@mui/material";
import { SearchTextField as SearchTextFieldBase } from "@/components/SearchTextField";

export const Root = styled("div")`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

export const SearchTextField = styled(SearchTextFieldBase)`
  width: 18.75rem;
`;

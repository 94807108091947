import { FC } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "@/components/Button";
import { ROUTES } from "@/routing/constants";
import * as Styled from "./UsersDataGridToolbar.styles";

interface IUsersDataGridToolbarProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
}

export const UsersDataGridToolbar: FC<IUsersDataGridToolbarProps> = ({
  searchValue,
  onSearchChange,
}) => {
  const location = useLocation();

  return (
    <Styled.Root>
      <Styled.SearchTextField value={searchValue} onChange={onSearchChange} />
      <Button href={ROUTES.usersCreate.createURL(location.search)}>
        Create User
      </Button>
    </Styled.Root>
  );
};

export const APP_NAME = "Payment Gateway";

export const EMPTY_VALUE_FALLBACK = "—";

export const API_BASE_URL = "/api/";

export const DEFAULT_ERROR_MESSAGE =
  "Whoops! Something went wrong. Please try again later.";

export const PAGE_SIZE_WITHOUT_PAGINATION = 1000;

import { FC, useCallback, useId } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";
import { Button } from "@/components/Button";
import { handleError } from "@/utils/handleError";
import { FormTextField } from "@/components/form";
import {
  useLazyGetUserDataQuery,
  useLazyResendEmailTwoFactorCodeQuery,
  useSubmitTwoFactorCodeMutation,
} from "@/features/auth/auth.api";
import { UserAuthType } from "@/types/user";
import { codeLocationHintByTwoFactorType } from "./constants";

enum FieldName {
  AuthCode = "authCode",
}

const formSchema = yup
  .object({
    [FieldName.AuthCode]: yup.string().required().trim().label("2-Factor Code"),
  })
  .required();

type FormValues = yup.InferType<typeof formSchema>;

interface ITwoFactorVerificationDialogProps extends DialogProps {
  type: UserAuthType;
  onClose: () => void;
}

export const TwoFactorVerificationDialog: FC<
  ITwoFactorVerificationDialogProps
> = ({ type, onClose, ...restProps }) => {
  const formId = useId();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(formSchema),
    mode: "onBlur",
  });

  const [resendEmailCode, { isFetching: isResendingEmailCode }] =
    useLazyResendEmailTwoFactorCodeQuery();
  const [submitCode, { isLoading: isSubmitting }] =
    useSubmitTwoFactorCodeMutation();
  const [getUserData] = useLazyGetUserDataQuery();

  const handleResendEmailCode = useCallback(async () => {
    try {
      await resendEmailCode().unwrap();

      toast.success(
        "An email with a new code was successfully sent to your email. Please check it.",
      );
    } catch (error) {
      handleError(error);
    }
  }, [resendEmailCode]);

  const handleFormSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await submitCode(values).unwrap();
        onClose();
        getUserData();
      } catch (error) {
        handleError(error);
      }
    },
    [submitCode, getUserData, onClose],
  );

  const codeLocationHint = codeLocationHintByTwoFactorType[type];

  return (
    <Dialog onClose={onClose} {...restProps}>
      <DialogTitle>2-Factor Verification</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          Please type in a 2-factor 6-digit verification code. To help keep your
          account safe, we want to make sure it’s really you trying to sign
          in.&nbsp;
          {codeLocationHint && <strong>{codeLocationHint}</strong>}
        </DialogContentText>
        <form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
          <FormTextField
            name={FieldName.AuthCode}
            control={control}
            label="2-Factor Code"
            disabled={isResendingEmailCode || isSubmitting}
            autoFocus
          />
        </form>
      </DialogContent>
      <DialogActions>
        {type === UserAuthType.Email && (
          <Button
            variant="text"
            disabled={isSubmitting}
            isLoading={isResendingEmailCode}
            onClick={handleResendEmailCode}
          >
            Resend code
          </Button>
        )}
        <Button
          form={formId}
          type="submit"
          disabled={isResendingEmailCode}
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

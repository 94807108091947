import { useMemo } from "react";
import { ITransactionEvent } from "@/features/transactionsManager/types";

interface ITransactionDetailsFilteredData {
  filteredData: ITransactionEvent[];
}

export const useTransactionDetailsFilter = (
  initialData: ITransactionEvent[],
  searchTerm?: string,
): ITransactionDetailsFilteredData => {
  const filteredData = useMemo(() => {
    if (!searchTerm) {
      return initialData;
    }

    return initialData.filter((event) =>
      Object.values(event).some((value) => {
        let valueToSearch =
          typeof value === "string" ? value : JSON.stringify(value);

        return valueToSearch.toLowerCase().includes(searchTerm.toLowerCase());
      }),
    );
  }, [initialData, searchTerm]);

  return { filteredData };
};

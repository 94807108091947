import { FC, useCallback } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { EmailVerificationDialog } from "@/features/auth/components/EmailVerificationDialog";
import { useAppSelector } from "@/hooks/useAppSelector";
import { useAppDispatch } from "@/hooks/useAppDispatch";
import {
  closeEmailVerificationDialog,
  closeTwoFactorVerificationDialog,
} from "@/features/auth/auth.slice";
import { TwoFactorVerificationDialog } from "@/features/auth/components/TwoFactorVerificationDialog";
import { UserAuthType } from "@/types/user";
import * as Styled from "./SignInPage.styles";

export const SignInPage: FC = () => {
  const location = useLocation();
  const {
    user,
    isEmailVerificationDialogOpen,
    activeTwoFactorVerificationDialogType,
  } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const handleEmailVerificationDialogClose = useCallback(() => {
    dispatch(closeEmailVerificationDialog());
  }, [dispatch]);

  const handleTwoFactorVerificationDialogClose = useCallback(() => {
    dispatch(closeTwoFactorVerificationDialog());
  }, [dispatch]);

  if (user) {
    const redirectTo = location.state?.from?.pathname || "/";

    return <Navigate to={redirectTo} />;
  }

  return (
    <Styled.Root>
      <Styled.SignInForm />
      <EmailVerificationDialog
        open={isEmailVerificationDialogOpen}
        onClose={handleEmailVerificationDialogClose}
      />
      <TwoFactorVerificationDialog
        open={activeTwoFactorVerificationDialogType !== UserAuthType.None}
        type={activeTwoFactorVerificationDialogType}
        onClose={handleTwoFactorVerificationDialogClose}
      />
    </Styled.Root>
  );
};

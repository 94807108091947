import { styled, Typography, alpha } from "@mui/material";

export const EmptyMessage = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 2rem;
  min-height: 3.668rem;
  background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.1)};
`;

import { FC, useState, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { AppBar } from "@/components/AppBar";
import { Sidebar } from "@/components/Sidebar";
import * as Styled from "./AppLayout.styles";

export const AppLayout: FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleToggleIsSidebarOpen = useCallback(() => {
    setIsSidebarOpen((prev) => !prev);
  }, []);

  return (
    <Styled.Root>
      <Sidebar
        isOpen={isSidebarOpen}
        onToggleOpen={handleToggleIsSidebarOpen}
      />
      <Styled.Container>
        <AppBar />
        <Styled.Content>
          <Outlet />
        </Styled.Content>
      </Styled.Container>
    </Styled.Root>
  );
};

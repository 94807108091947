import { FC, useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FieldName,
  UserFormDefaultValues,
  UserFormDialog,
  UserFormValues,
} from "@/features/users/components/UserFormDialog";
import { LoadingOverlay } from "@/components/LoadingOverlay";
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "@/features/users/usersApi";
import { handleError } from "@/utils/handleError";

interface IUserEditDialogProps {
  open: boolean;
}

const disabledFields = [
  FieldName.Role,
  FieldName.OperatorId,
  FieldName.MerchantId,
];

export const UserEditDialog: FC<IUserEditDialogProps> = ({ open }) => {
  const { id: idParam } = useParams<"id">();
  const id = Number(idParam);

  const {
    isFetching: isFetchingUser,
    data: user,
    error: userFetchingError,
  } = useGetUserQuery({ id });

  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

  const defaultValues: UserFormDefaultValues = useMemo(() => {
    if (!user) {
      return {};
    }

    const {
      operatorIdBlackList,
      merchantIdBlackList,
      ipWhiteList,
      ...restUser
    } = user;

    return {
      ...restUser,
      operatorsAndMerchantsBlackList: {
        operatorIds: operatorIdBlackList,
        merchantIds: merchantIdBlackList,
      },
      merchantIdBlackList,
      ipWhiteList: (ipWhiteList || []).map((ip) => ({
        value: ip,
      })),
    };
  }, [user]);

  const handleSubmit = useCallback(
    async ({
      fullName,
      operatorsAndMerchantsBlackList,
      merchantIdBlackList,
      ipWhiteList = [],
      ...restValues
    }: UserFormValues) => {
      await updateUser({
        ...restValues,
        id,
        fullName,
        operatorIdBlackList: operatorsAndMerchantsBlackList.operatorIds || [],
        merchantIdBlackList:
          merchantIdBlackList ||
          operatorsAndMerchantsBlackList.merchantIds ||
          [],
        ipWhiteList: ipWhiteList.map(({ value }) => value),
      }).unwrap();

      toast.success(`${fullName} has been successfully updated.`);
    },
    [id, updateUser],
  );

  useEffect(() => {
    if (userFetchingError) {
      handleError(userFetchingError);
    }
  }, [userFetchingError]);

  return (
    <UserFormDialog
      title={`Edit User ${user?.fullName || ""}`}
      submitButtonText="Update"
      defaultValues={defaultValues}
      isEmailVerifiedBadgeVisible={user?.isEmailConfirmed}
      isSubmitting={isUpdating}
      onSubmit={handleSubmit}
      open={open}
      disabledFields={disabledFields}
    >
      <LoadingOverlay open={isFetchingUser} />
    </UserFormDialog>
  );
};

import { api } from "@/store/api";
import {
  ISignInRequestBody,
  ISignInResponseBody,
  ISubmitTwoFactorCodeRequestBody,
  ISubmitTwoFactorCodeResponseBody,
  IAuthUser,
  IVerifyEmailRequestBody,
} from "@/features/auth/types";
import { formatSecondsInterval } from "@/utils/dataFormat/formatSecondsInterval";

const URL_PREFIX = "/auth";

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<ISignInResponseBody, ISignInRequestBody>({
      query: (data) => ({
        url: `${URL_PREFIX}/login`,
        method: "POST",
        body: data,
      }),
    }),
    getUserData: builder.query<IAuthUser, void>({
      query: () => ({
        url: `${URL_PREFIX}/check`,
      }),
    }),
    resendVerificationEmail: builder.query<void, void>({
      query: () => ({
        url: `${URL_PREFIX}/resend-email-verification`,
      }),
      transformErrorResponse: (errorResponse, meta) => {
        const { status } = errorResponse;

        if (status === 429) {
          let message = "Request limit is reached. Please try again later.";

          const retryAfterHeaderValue =
            meta?.response?.headers.get("Retry-After") || null;

          if (retryAfterHeaderValue) {
            const retryAfterSeconds = Number(retryAfterHeaderValue);
            const formattedDuration = formatSecondsInterval(retryAfterSeconds);

            message = `Verification email can be sent again in ${formattedDuration}.`;
          }

          return {
            status: 429,
            data: message,
          };
        } else {
          return errorResponse;
        }
      },
    }),
    verifyEmail: builder.query<void, IVerifyEmailRequestBody>({
      query: ({ token }) => ({
        url: `${URL_PREFIX}/email-verification?token=${token}`,
      }),
    }),
    resendEmailTwoFactorCode: builder.query<void, void>({
      query: () => ({
        url: `${URL_PREFIX}/resend-email-auth-code`,
      }),
    }),
    submitTwoFactorCode: builder.mutation<
      ISubmitTwoFactorCodeResponseBody,
      ISubmitTwoFactorCodeRequestBody
    >({
      query: (data) => ({
        url: `${URL_PREFIX}/two-fa`,
        method: "POST",
        body: data,
      }),
    }),
    signOut: builder.mutation<void, void>({
      query: () => ({
        url: `${URL_PREFIX}/logout`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSignInMutation,
  useLazyGetUserDataQuery,
  useGetUserDataQuery,
  useLazyResendVerificationEmailQuery,
  useLazyVerifyEmailQuery,
  useLazyResendEmailTwoFactorCodeQuery,
  useSubmitTwoFactorCodeMutation,
  useSignOutMutation,
} = authApi;

import { FC } from "react";
import { DataGrid, IDataGridColumns } from "@/components/DataGrid";
import { ITransactionEvent } from "@/features/transactionsManager/types";
import { formatDate } from "@/utils/dataFormat/formatDate";
import { JsonViewer } from "@/components/JsonViewer";

enum TransactionDetailsDataGridField {
  CreatedAt = "createdAt",
  Status = "status",
  Event = "event",
  Data = "data",
}

const columns: IDataGridColumns<ITransactionEvent> = [
  {
    field: TransactionDetailsDataGridField.CreatedAt,
    headerName: "Time",
    headCellSx: {
      minWidth: 240,
    },
    renderCell: ({ rowData }) => formatDate(rowData.createdAt),
  },
  {
    field: TransactionDetailsDataGridField.Status,
    headerName: "Status",
    headCellSx: {
      minWidth: 200,
    },
  },
  {
    field: TransactionDetailsDataGridField.Event,
    headerName: "Step",
    headCellSx: {
      minWidth: 200,
    },
  },
  {
    field: TransactionDetailsDataGridField.Data,
    headerName: "Content",
    headCellSx: {
      minWidth: 340,
    },
    renderCell: ({ rowData }) => {
      const { data } = rowData;

      if (typeof data === "object" && data !== null) {
        return <JsonViewer src={data} />;
      }

      return data;
    },
  },
];

interface ITransactionDetailsDataGridProps {
  className?: string;
  isFetching: boolean;
  items: ITransactionEvent[];
}

const getRowId = ({ event, status, createdAt }: ITransactionEvent) => {
  return event + status + createdAt;
};

export const TransactionDetailsDataGrid: FC<
  ITransactionDetailsDataGridProps
> = ({ className, isFetching, items }) => {
  return (
    <DataGrid
      className={className}
      columns={columns}
      data={items}
      getRowId={getRowId}
      emptyMessage="Whoops! There're no details at the moment :("
      loading={{
        isLoadingTable: isFetching,
      }}
    />
  );
};

import { ChangeEvent, FC, Fragment, useCallback, useMemo } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import {
  CheckboxTreeNodes,
  CheckboxTreeValues,
} from "@/components/CheckboxTree/types";
import {
  getAllNodesValues,
  getCheckboxState,
} from "@/components/CheckboxTree/utils";
import * as Styled from "./CheckboxTree.styles";

export interface ICheckboxTreeProps {
  checkedValues: CheckboxTreeValues;
  nodes: CheckboxTreeNodes;
  disabled?: boolean;
  withInverse?: boolean;
  withSelectAll?: boolean;
  onChange: (checkedValues: CheckboxTreeValues) => void;
}

export const CheckboxTree: FC<ICheckboxTreeProps> = ({
  nodes,
  checkedValues,
  disabled,
  withInverse,
  withSelectAll,
  onChange,
}) => {
  const allNodesValues = useMemo(() => getAllNodesValues(nodes), [nodes]);

  const isSelectAllChecked = useMemo(() => {
    if (withInverse) {
      return !checkedValues.length;
    }

    return allNodesValues.every((value) => checkedValues.includes(value));
  }, [allNodesValues, checkedValues, withInverse]);

  const handleSelectAllChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let { checked } = event.target;

      if (withInverse) {
        checked = !checked;
      }

      onChange(checked ? allNodesValues : []);
    },
    [allNodesValues, withInverse, onChange],
  );

  if (!nodes.length) {
    return null;
  }

  return (
    <Styled.Root>
      {withSelectAll && (
        <FormControlLabel
          label="Select All"
          disabled={disabled}
          control={
            <Checkbox
              checked={isSelectAllChecked}
              onChange={handleSelectAllChange}
            />
          }
        />
      )}

      {nodes.map((node) => {
        const { value, label, children } = node;
        const checkboxState = getCheckboxState(
          checkedValues,
          node,
          withInverse,
        );

        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
          let isChecked = event.target.checked;

          if (withInverse) {
            isChecked = !isChecked;
          }

          const childrenValues = getAllNodesValues(children);

          let newChecked = checkedValues.filter((checkedValue) => {
            return ![...childrenValues, value].includes(checkedValue);
          });

          if (isChecked) {
            if (childrenValues.length) {
              newChecked = newChecked.concat(childrenValues);
            } else {
              newChecked.push(value);
            }
          }

          onChange(newChecked);
        };

        return (
          <Fragment key={value}>
            <FormControlLabel
              label={label}
              disabled={disabled}
              control={<Checkbox {...checkboxState} onChange={handleChange} />}
            />
            {!!children?.length && (
              <Styled.NestedNodes>
                <CheckboxTree
                  checkedValues={checkedValues}
                  nodes={children}
                  disabled={disabled}
                  withInverse={withInverse}
                  onChange={onChange}
                />
              </Styled.NestedNodes>
            )}
          </Fragment>
        );
      })}
    </Styled.Root>
  );
};

import { FC, ReactNode } from "react";
import { useGetUserDataQuery } from "@/features/auth/auth.api";
import { LoadingOverlay } from "@/components/LoadingOverlay";

interface IAuthLoaderProps {
  children: ReactNode;
}

export const AuthLoader: FC<IAuthLoaderProps> = ({ children }) => {
  const { isFetching } = useGetUserDataQuery();

  return (
    <>
      {!isFetching && children}
      <LoadingOverlay open={isFetching} />
    </>
  );
};

import { useEffect } from "react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { handleError } from "@/utils/handleError";
import { TransactionType } from "@/types/transaction";
import {
  useLazyGetDepositTransactionsExportQuery,
  useLazyGetWithdrawalTransactionsExportQuery,
} from "@/features/transactionsManager/transactionsApi";

interface IUseTransactionExportProps {
  transactionType: TransactionType;
}

export const useTransactionExport = ({
  transactionType,
}: IUseTransactionExportProps) => {
  const [
    getDepositExport,
    { isFetching: isFetchingDepositExport, error: depositExportError },
  ] = useLazyGetDepositTransactionsExportQuery();

  const [
    getWithdrawalExport,
    { isFetching: isFetchingWithdrawalExport, error: withdrawalExportError },
  ] = useLazyGetWithdrawalTransactionsExportQuery();

  const queryByTransactionType: Record<
    TransactionType,
    typeof getDepositExport | typeof getWithdrawalExport
  > = {
    [TransactionType.Deposit]: getDepositExport,
    [TransactionType.Withdrawal]: getWithdrawalExport,
  };

  const isFetchingByTransactionType: Record<TransactionType, boolean> = {
    [TransactionType.Deposit]: isFetchingDepositExport,
    [TransactionType.Withdrawal]: isFetchingWithdrawalExport,
  };

  const errorByTransactionType: Record<
    TransactionType,
    FetchBaseQueryError | SerializedError | undefined
  > = {
    [TransactionType.Deposit]: depositExportError,
    [TransactionType.Withdrawal]: withdrawalExportError,
  };

  const query = queryByTransactionType[transactionType];
  const isFetching = isFetchingByTransactionType[transactionType];
  const error = errorByTransactionType[transactionType];

  useEffect(() => {
    if (error) {
      handleError(
        error,
        "Whoops! An error happen while exporting transactions. Please try again later.",
      );
    }
  }, [error]);

  return {
    isFetching: isFetching,
    getTransactionExport: query,
  };
};

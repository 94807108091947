import { FC } from "react";
import * as Styled from "./TransactionDetailsDataGridToolbar.styles";

interface ITransactionDetailsDataGridToolbarProps {
  className?: string;
  searchValue: string;
  onSearchChange: (value: string) => void;
}

export const TransactionDetailsDataGridToolbar: FC<
  ITransactionDetailsDataGridToolbarProps
> = ({ searchValue, onSearchChange, className }) => (
  <Styled.Root className={className}>
    <Styled.SearchTextField value={searchValue} onChange={onSearchChange} />
  </Styled.Root>
);

import { FC, useMemo } from "react";
import {
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
  Group as GroupIcon,
  SyncAlt as SyncAltIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { List, Divider, IconButton, ListItemText } from "@mui/material";
import { useUser } from "@/hooks/useUser";
import { FeatureTitle } from "@/types/featureTitle";
import { ROUTES } from "@/routing/constants";
import { ISidebarItem } from "@/components/Sidebar/types";
import * as Styled from "./Sidebar.styles";

interface ISidebarProps {
  isOpen: boolean;
  onToggleOpen: () => void;
}

export const Sidebar: FC<ISidebarProps> = ({ isOpen, onToggleOpen }) => {
  const { permissions } = useUser();

  const sidebarItems = useMemo(() => {
    const result: ISidebarItem[] = [
      {
        title: FeatureTitle.Dashboard,
        url: ROUTES.dashboard.createURL(),
        Icon: DashboardIcon,
      },
      {
        title: FeatureTitle.TransactionManager,
        url: ROUTES.transactionsManager.createURL(),
        Icon: SyncAltIcon,
      },
    ];

    if (permissions.users) {
      result.push({
        title: FeatureTitle.UserManagement,
        url: ROUTES.users.createURL(),
        Icon: GroupIcon,
      });
    }

    return result;
  }, [permissions]);

  return (
    <Styled.Drawer variant="permanent" $isOpen={isOpen}>
      <Styled.DrawerHeader>
        <IconButton onClick={onToggleOpen}>
          {isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      </Styled.DrawerHeader>
      <Divider />
      <List>
        {sidebarItems.map(({ title, url, Icon }) => (
          <Styled.ListItem key={url} disablePadding>
            <Styled.Link to={url}>
              <Styled.ListItemIcon $isOpen={isOpen}>
                {<Icon />}
              </Styled.ListItemIcon>
              {isOpen && <ListItemText primary={title} />}
            </Styled.Link>
          </Styled.ListItem>
        ))}
      </List>
      <Divider />
    </Styled.Drawer>
  );
};

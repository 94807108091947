import { useMemo } from "react";
import { FieldValues } from "react-hook-form";
import {
  FormSelectField,
  FormSelectFieldOptions,
  FormSelectFieldProps,
} from "@/components/form/FormSelectField";
import { MerchantId, Merchants } from "@/types/merchant";

type Options = FormSelectFieldOptions<MerchantId>;

interface IMerchantFieldProps<TFormValues extends FieldValues>
  extends Omit<FormSelectFieldProps<TFormValues, MerchantId>, "options"> {
  merchants: Merchants;
}

export const MerchantField = <TFormValues extends FieldValues>({
  label = "Merchant",
  merchants,
  ...restProps
}: IMerchantFieldProps<TFormValues>) => {
  const options = useMemo<Options>(
    () => convertMerchantsToOptions(merchants),
    [merchants],
  );

  return <FormSelectField label={label} options={options} {...restProps} />;
};

const convertMerchantsToOptions = (merchants: Merchants): Options => {
  return merchants.map((merchant) => ({
    value: merchant.id,
    label: merchant.name,
  }));
};

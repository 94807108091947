import { FC, useCallback, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { LoadingOverlay } from "@/components/LoadingOverlay";
import { ROUTES } from "@/routing/constants";
import { delay } from "@/utils/delay";
import { handleError } from "@/utils/handleError";
import { useLazyVerifyEmailQuery } from "@/features/auth/auth.api";
import * as Styled from "./EmailVerificationPage.styles";

const TOKEN_SEARCH_PARAM = "token";

const DELAY_BEFORE_REDIRECT_IN_MS = 5_000;

export const EmailVerificationPage: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isVerificationStartedRef = useRef(false);

  const [verifyEmail, { isFetching, isSuccess }] = useLazyVerifyEmailQuery();

  const handleNavigateToSignIn = useCallback(() => {
    navigate(ROUTES.signIn.createURL(), { replace: true });
  }, [navigate]);

  const handleVerifyEmail = useCallback(
    async (token: string) => {
      try {
        await verifyEmail({ token }).unwrap();
        await delay(DELAY_BEFORE_REDIRECT_IN_MS);
        handleNavigateToSignIn();
      } catch (error) {
        handleError(error);
        handleNavigateToSignIn();
      }
    },
    [verifyEmail, handleNavigateToSignIn],
  );

  useEffect(() => {
    if (isVerificationStartedRef.current) {
      return;
    }

    const token = searchParams.get(TOKEN_SEARCH_PARAM);

    if (token) {
      isVerificationStartedRef.current = true;
      handleVerifyEmail(token);
    } else {
      handleNavigateToSignIn();
    }
  }, [searchParams, handleVerifyEmail, handleNavigateToSignIn]);

  return (
    <Styled.Root>
      {!isFetching && isSuccess && (
        <Typography variant="h4">
          Your email was successfully verified
        </Typography>
      )}
      <LoadingOverlay open={isFetching} />
    </Styled.Root>
  );
};

import { IActionsButtonItem } from "@/components/ActionsButton";

export enum UserActionTitle {
  EditUser = "Edit user",
  EnableUser = "Enable user",
  DisableUser = "Disable user",
  ResetQR = "Reset QR code",
  ResetPassword = "Reset password",
  DeleteUser = "Delete user",
}

export interface IUserActionsItem extends IActionsButtonItem<UserActionTitle> {}

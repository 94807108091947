import { useMemo } from "react";
import {
  IDepositTransaction,
  IWithdrawalTransaction,
  TransactionStatus,
} from "@/features/transactionsManager/types";
import {
  ITransactionActionsItem,
  TransactionActionTitle,
} from "@/features/transactionsManager/components/TransactionActionsButton/types";
import { ROUTES } from "@/routing/constants";
import { useNavigate, useParams } from "react-router-dom";
import { TransactionType } from "@/types/transaction";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useTransactionActionsMutation } from "@/features/transactionsManager/components/TransactionActionsButton/useTransactionActionsMutation";
import { toast } from "react-toastify";
import { handleError } from "@/utils/handleError";

interface IUseTransactionActionsItemsData {
  transaction: IDepositTransaction | IWithdrawalTransaction;
}

type TransactionActionsParams = {
  transactionType: TransactionType;
};

export const useTransactionActions = ({
  transaction,
}: IUseTransactionActionsItemsData) => {
  const { parsedSearchParams } = useSearchParams();
  const navigate = useNavigate();

  const { transactionType = TransactionType.Deposit } =
    useParams<TransactionActionsParams>();

  const { resendCallback, updateStatus, approveTransaction } =
    useTransactionActionsMutation({ transactionType });

  const transactionActionsItems = useMemo(() => {
    const { internalTxnId } = transaction;

    const isVisibleByActionTitle: Partial<
      Record<TransactionActionTitle, () => boolean>
    > = {
      [TransactionActionTitle.Release]: () =>
        transactionType === TransactionType.Withdrawal,
    };

    const itemsMap: Record<TransactionActionTitle, ITransactionActionsItem> = {
      [TransactionActionTitle.TransactionDetails]: {
        title: TransactionActionTitle.TransactionDetails,
        onClick: () => {
          navigate(
            ROUTES.transactionsDetails.createURL(
              internalTxnId,
              transactionType,
              parsedSearchParams,
            ),
          );
        },
      },
      [TransactionActionTitle.ResendCallbackToMerchant]: {
        title: TransactionActionTitle.ResendCallbackToMerchant,
        onClick: async () => {
          try {
            await resendCallback({ id: internalTxnId }).unwrap();
            toast.success(
              `The callback for ${transaction.internalTxnId} has been resent.`,
            );
          } catch (error) {
            handleError(error);
          }
        },
      },
      [TransactionActionTitle.RequestStatusFromProvider]: {
        title: TransactionActionTitle.RequestStatusFromProvider,
        onClick: async () => {
          try {
            await updateStatus({ id: internalTxnId }).unwrap();
            toast.success(
              `The status for ${transaction.internalTxnId} has been updated.`,
            );
          } catch (error) {
            handleError(error);
          }
        },
      },
      [TransactionActionTitle.Release]: {
        title: TransactionActionTitle.Release,
        onClick: async () => {
          try {
            await approveTransaction({ id: internalTxnId }).unwrap();
            toast.success(
              `The transaction ${transaction.internalTxnId} has been approved.`,
            );
          } catch (error) {
            handleError(error);
          }
        },
        disabled: !(
          "isApproved" in transaction &&
          transaction.isApproved &&
          transaction.status === TransactionStatus.Pending
        ),
      },
    };

    return Object.values(TransactionActionTitle).reduce((accumulator, key) => {
      const isVisiblePredicate = isVisibleByActionTitle[key];

      if (isVisiblePredicate && !isVisiblePredicate()) {
        return accumulator;
      }

      return [...accumulator, itemsMap[key]];
    }, [] as ITransactionActionsItem[]);
  }, [
    transaction,
    transactionType,
    navigate,
    parsedSearchParams,
    resendCallback,
    updateStatus,
    approveTransaction,
  ]);
  return {
    transactionActionsItems,
  };
};

import { useCallback, useMemo } from "react";
import { UserAuthType } from "@/types/user";
import { IUser, UserStatus } from "@/features/users/types";
import {
  IUserActionsItem,
  UserActionTitle,
} from "@/features/users/components/UserActionsButton/types";
import { useConfirmDialog } from "@/hooks/useConfirmDialog";
import {
  useDeleteUserMutation,
  useResetPasswordMutation,
  useResetQRCodeMutation,
  useSwitchUserStatusMutation,
} from "@/features/users/usersApi";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "@/routing/constants";

interface IUseUserActionsItemsData {
  user: IUser;
}

export const useUserActions = ({ user }: IUseUserActionsItemsData) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [switchUserStatus] = useSwitchUserStatusMutation();
  const [resetPassword] = useResetPasswordMutation();
  const [resetQRCode] = useResetQRCodeMutation();
  const [deleteUser] = useDeleteUserMutation();

  const { showConfirmDialog } = useConfirmDialog();

  const handleShowConfirmDialog = useCallback(
    (message: string, onConfirm: () => void) => {
      showConfirmDialog({
        message: message,
        onConfirm,
      });
    },
    [showConfirmDialog],
  );

  const userActionsItems = useMemo(() => {
    const { status, authType } = user;

    const isVisibleByActionTitle: Partial<
      Record<UserActionTitle, () => boolean>
    > = {
      [UserActionTitle.EnableUser]: () => status === UserStatus.Inactive,
      [UserActionTitle.DisableUser]: () => status === UserStatus.Active,
      [UserActionTitle.ResetQR]: () =>
        authType === UserAuthType.GoogleAuthenticator,
    };
    const itemsMap: Record<UserActionTitle, IUserActionsItem> = {
      [UserActionTitle.EditUser]: {
        title: UserActionTitle.EditUser,
        onClick: () => {
          navigate(ROUTES.usersEdit.createURL(user.id, location.search));
        },
      },
      [UserActionTitle.EnableUser]: {
        title: UserActionTitle.EnableUser,
        onClick: () => {
          handleShowConfirmDialog(
            `Are you sure you want to enable user ${user.fullName}?`,
            async () => {
              await switchUserStatus({
                id: user.id,
                status: UserStatus.Active,
              }).unwrap();
              toast.success(`User ${user.fullName} has been enabled.`);
            },
          );
        },
      },
      [UserActionTitle.DisableUser]: {
        title: UserActionTitle.DisableUser,
        onClick: () => {
          handleShowConfirmDialog(
            `Are you sure you want to disable user ${user.fullName}?`,
            async () => {
              await switchUserStatus({
                id: user.id,
                status: UserStatus.Inactive,
              }).unwrap();
              toast.success(`User ${user.fullName} has been disabled.`);
            },
          );
        },
      },
      [UserActionTitle.ResetQR]: {
        title: UserActionTitle.ResetQR,
        onClick: () => {
          handleShowConfirmDialog(
            `Are you sure you want to reset QR code for user ${user.fullName}?`,
            async () => {
              await resetQRCode({ id: user.id }).unwrap();
              toast.success(
                `QR code for user ${user.fullName} has been reset.`,
              );
            },
          );
        },
      },
      [UserActionTitle.ResetPassword]: {
        title: UserActionTitle.ResetPassword,
        onClick: () => {
          handleShowConfirmDialog(
            `Are you sure you want to reset password for user ${user.fullName}?`,
            async () => {
              await resetPassword({ id: user.id }).unwrap();
              toast.success(
                `Password for user ${user.fullName} has been reset.`,
              );
            },
          );
        },
      },
      [UserActionTitle.DeleteUser]: {
        title: UserActionTitle.DeleteUser,
        onClick: () => {
          handleShowConfirmDialog(
            `Are you sure you want to delete user ${user.fullName}?`,
            async () => {
              await deleteUser({ id: user.id }).unwrap();
              toast.success(`User ${user.fullName} has been deleted.`);
            },
          );
        },
      },
    };

    return Object.values(UserActionTitle).reduce((accumulator, key) => {
      const isVisiblePredicate = isVisibleByActionTitle[key];

      if (isVisiblePredicate && !isVisiblePredicate()) {
        return accumulator;
      }

      return [...accumulator, itemsMap[key]];
    }, [] as IUserActionsItem[]);
  }, [
    navigate,
    location.search,
    user,
    handleShowConfirmDialog,
    switchUserStatus,
    resetQRCode,
    resetPassword,
    deleteUser,
  ]);

  return {
    userActionsItems,
  };
};

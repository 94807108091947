import { FC } from "react";
import { Tab, Tabs, TabsProps } from "@mui/material";
import { ROUTES } from "@/routing/constants";
import { TransactionType } from "@/types/transaction";

const transactionTypes = Object.values(TransactionType);

const transactionTypeLabelMap: Record<TransactionType, string> = {
  [TransactionType.Deposit]: "Deposits",
  [TransactionType.Withdrawal]: "Withdrawals",
};

export const TransactionsManagerTabs: FC<TabsProps> = (props) => (
  <Tabs {...props}>
    {transactionTypes.map((transactionType) => (
      <Tab
        key={transactionType}
        value={transactionType}
        label={transactionTypeLabelMap[transactionType]}
        href={ROUTES.transactionsManager.createURL(transactionType)}
      />
    ))}
  </Tabs>
);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuthUser } from "@/features/auth/types";
import { UserAuthType } from "@/types/user";
import { authApi } from "@/features/auth/auth.api";

interface IAuthState {
  isEmailVerificationDialogOpen: boolean;
  activeTwoFactorVerificationDialogType: UserAuthType;
  user: IAuthUser | null;
}

const initialState: IAuthState = {
  isEmailVerificationDialogOpen: false,
  activeTwoFactorVerificationDialogType: UserAuthType.None,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    openEmailVerificationDialog: (state) => {
      state.isEmailVerificationDialogOpen = true;
    },
    closeEmailVerificationDialog: (state) => {
      state.isEmailVerificationDialogOpen = false;
    },
    openTwoFactorVerificationDialog: (
      state,
      action: PayloadAction<UserAuthType>,
    ) => {
      state.activeTwoFactorVerificationDialogType = action.payload;
    },
    closeTwoFactorVerificationDialog: (state) => {
      state.activeTwoFactorVerificationDialogType = UserAuthType.None;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.getUserData.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
      },
    );
    builder.addMatcher(authApi.endpoints.signOut.matchPending, (state) => {
      state.user = null;
    });
  },
});

const { reducer, actions } = authSlice;

export const {
  openEmailVerificationDialog,
  closeEmailVerificationDialog,
  openTwoFactorVerificationDialog,
  closeTwoFactorVerificationDialog,
} = actions;

export const authReducer = reducer;

import { api } from "@/store/api";
import { IGetOperatorsResponseBody } from "@/features/operators/types";
import { PAGE_SIZE_WITHOUT_PAGINATION } from "@/constants/common";

const API_PREFIX = "/operators";

const operatorsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOperators: builder.query<IGetOperatorsResponseBody, void>({
      query: () => ({
        url: API_PREFIX,
        params: {
          pageSize: PAGE_SIZE_WITHOUT_PAGINATION,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetOperatorsQuery } = operatorsApi;

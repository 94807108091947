import {
  CheckboxTreeValues,
  ICheckboxState,
  ICheckboxTreeNode,
} from "@/components/CheckboxTree/types";
import { getAllNodesValues } from "@/components/CheckboxTree/utils/getAllNodesValues";

export const getCheckboxState = (
  checkedValues: CheckboxTreeValues,
  node: ICheckboxTreeNode,
  withInverse?: boolean,
): ICheckboxState => {
  let checked = false;
  let indeterminate = false;

  const { value, children } = node;

  if (!children?.length) {
    checked = checkedValues.includes(value);
  } else {
    const childrenValues = getAllNodesValues(children);
    const totalChildrenCount = children.length;

    let checkedCount = 0;

    childrenValues.forEach((childValue) => {
      if (checkedValues.includes(childValue)) {
        checkedCount++;
      }
    });

    checked = checkedCount === totalChildrenCount;
    indeterminate = checkedCount > 0 && checkedCount < totalChildrenCount;
  }

  return {
    checked: withInverse ? !checked : checked,
    indeterminate,
  };
};

import { api } from "@/store/api";
import {
  IGetDepositTransactionsFiltersRequestData,
  ITransactionDetailsResponseBody,
  IGetTransactionsFiltersResponseBody,
  IGetWithdrawTransactionsFiltersRequestData,
  TransactionsDetailsRequestBody,
  IGetDepositTransactionsResponseData,
  IGetWithdrawalTransactionsResponseData,
  IGetDepositTransactionsRequestData,
  IGetWithdrawalTransactionsRequestData,
  ITransactionsExportResponseBody,
} from "@/features/transactionsManager/types";
import { TransactionId } from "@/types/transaction";
import { ApiTag } from "@/types/api";

const DEPOSIT_PREFIX = "deposit";
const WITHDRAWAL_PREFIX = "withdrawal";

const transactionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTransactionDetails: builder.query<
      ITransactionDetailsResponseBody,
      TransactionsDetailsRequestBody
    >({
      query: ({ transactionType, id }) => ({
        url: `${transactionType}/${id}`,
      }),
    }),
    getDepositTransactions: builder.query<
      IGetDepositTransactionsResponseData,
      IGetDepositTransactionsRequestData
    >({
      query: (params) => ({
        url: `${DEPOSIT_PREFIX}/list`,
        params,
      }),
      providesTags: [ApiTag.DepositTransactionsList],
    }),
    getDepositWithdrawals: builder.query<
      IGetWithdrawalTransactionsResponseData,
      IGetWithdrawalTransactionsRequestData
    >({
      query: (params) => ({
        url: `${WITHDRAWAL_PREFIX}/list`,
        params,
      }),
      providesTags: [ApiTag.WithdrawalTransactionsList],
    }),
    getDepositTransactionsFilters: builder.query<
      IGetTransactionsFiltersResponseBody,
      IGetDepositTransactionsFiltersRequestData | void
    >({
      query: (data) => ({
        url: `${DEPOSIT_PREFIX}/filters`,
        params: {
          filters: data,
        },
      }),
    }),
    getWithdrawalTransactionsFilters: builder.query<
      IGetTransactionsFiltersResponseBody,
      IGetWithdrawTransactionsFiltersRequestData | void
    >({
      query: (data) => ({
        url: `${WITHDRAWAL_PREFIX}/filters`,
        params: {
          filters: data,
        },
      }),
    }),
    getDepositTransactionsExport: builder.query<
      ITransactionsExportResponseBody,
      IGetDepositTransactionsRequestData
    >({
      query: (data) => ({
        url: `${DEPOSIT_PREFIX}/export`,
        params: data,
      }),
    }),
    getWithdrawalTransactionsExport: builder.query<
      ITransactionsExportResponseBody,
      IGetWithdrawalTransactionsRequestData
    >({
      query: (data) => ({
        url: `${WITHDRAWAL_PREFIX}/export`,
        params: data,
      }),
    }),
    resendDepositTransactionCallback: builder.mutation<
      void,
      { id: TransactionId }
    >({
      query: ({ id }) => ({
        url: `${DEPOSIT_PREFIX}/${id}/callback`,
        method: "POST",
      }),
      invalidatesTags: [ApiTag.DepositTransactionsList],
    }),
    resendWithdrawalTransactionCallback: builder.mutation<
      void,
      { id: TransactionId }
    >({
      query: ({ id }) => ({
        url: `${WITHDRAWAL_PREFIX}/${id}/callback`,
        method: "POST",
      }),
      invalidatesTags: [ApiTag.WithdrawalTransactionsList],
    }),
    updateDepositTransactionStatus: builder.mutation<
      void,
      { id: TransactionId }
    >({
      query: ({ id }) => ({
        url: `${DEPOSIT_PREFIX}/${id}/update-status`,
        method: "POST",
      }),
      invalidatesTags: [ApiTag.DepositTransactionsList],
    }),
    updateWithdrawalTransactionStatus: builder.mutation<
      void,
      { id: TransactionId }
    >({
      query: ({ id }) => ({
        url: `${WITHDRAWAL_PREFIX}/${id}/update-status`,
        method: "POST",
      }),
      invalidatesTags: [ApiTag.WithdrawalTransactionsList],
    }),
    approveWithdrawalTransaction: builder.mutation<void, { id: TransactionId }>(
      {
        query: ({ id }) => ({
          url: `${WITHDRAWAL_PREFIX}/${id}/approve`,
          method: "PUT",
        }),
        invalidatesTags: [ApiTag.WithdrawalTransactionsList],
      },
    ),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetWithdrawalTransactionsFiltersQuery,
  useLazyGetDepositTransactionsFiltersQuery,
  useGetDepositTransactionsQuery,
  useGetDepositWithdrawalsQuery,
  useGetTransactionDetailsQuery,
  useLazyGetDepositTransactionsExportQuery,
  useLazyGetWithdrawalTransactionsExportQuery,
  useResendDepositTransactionCallbackMutation,
  useResendWithdrawalTransactionCallbackMutation,
  useUpdateDepositTransactionStatusMutation,
  useUpdateWithdrawalTransactionStatusMutation,
  useApproveWithdrawalTransactionMutation,
} = transactionsApi;

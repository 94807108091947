import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import {
  IPermissionsRestrictedProps,
  PermissionsRestricted,
} from "@/components/PermissionsRestricted";

interface IPermissionsRestrictedRouteProps
  extends Omit<IPermissionsRestrictedProps, "children"> {}

const renderNotPermitted = () => {
  return <Navigate to="/" replace />;
};

export const PermissionsRestrictedRoute: FC<
  IPermissionsRestrictedRouteProps
> = (props) => (
  <PermissionsRestricted renderNotPermitted={renderNotPermitted} {...props}>
    <Outlet />
  </PermissionsRestricted>
);

import { GlobalStyles, CssBaseline, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { Router } from "@/routing/Router";
import "react-toastify/dist/ReactToastify.css";
import { globalStyles } from "./styles/global";
import { AuthLoader } from "@/features/auth/components/AuthLoader";
import { ConfirmDialogProvider } from "@/contexts/ConfirmDialogContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { darkTheme } from "./styles/theme";

export const App = () => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />
      <AuthLoader>
        <ConfirmDialogProvider>
          <Router />
        </ConfirmDialogProvider>
      </AuthLoader>
      <ToastContainer />
    </ThemeProvider>
  </LocalizationProvider>
);

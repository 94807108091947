import { FC, useCallback } from "react";
import { useTransactionExport } from "@/features/transactionsManager/components/TransactionExportButton/useTransactionExport";
import { TransactionType } from "@/types/transaction";
import {
  IGetDepositTransactionsRequestData,
  IGetWithdrawalTransactionsRequestData,
} from "@/features/transactionsManager/types";
import { Button } from "@/components/Button";
import { useLazyGetFileQuery } from "@/features/app/appApiSlice";
import { handleError } from "@/utils/handleError";
import { toast } from "react-toastify";
import { downloadFile } from "@/utils/downloadFile";
import { format } from "date-fns";

interface ITransactionExportButtonProps {
  transactionType: TransactionType;
  requestData: IGetDepositTransactionsRequestData &
    IGetWithdrawalTransactionsRequestData;
}

export const TransactionExportButton: FC<ITransactionExportButtonProps> = ({
  transactionType,
  requestData,
}) => {
  const { getTransactionExport, isFetching } = useTransactionExport({
    transactionType,
  });

  const [getFile, { isFetching: isFetchingFile }] = useLazyGetFileQuery();

  const getFileName = useCallback(() => {
    return `transactions_${transactionType}_${format(
      new Date(),
      "yyyy-MM-dd_hh-mm-ss",
    )}`;
  }, [transactionType]);

  const handleExport = useCallback(async () => {
    try {
      const exportData = await getTransactionExport(requestData).unwrap();
      const file = await getFile(exportData).unwrap();
      downloadFile(file, getFileName(), { type: "text/csv" });

      toast.success("Your file is downloading.");
    } catch (error) {
      handleError(
        error,
        "Whoops! An error happen while exporting transactions. Please try again later.",
      );
    }
  }, [getFile, getFileName, getTransactionExport, requestData]);

  return (
    <Button isLoading={isFetching || isFetchingFile} onClick={handleExport}>
      Export CSV
    </Button>
  );
};

import { createStyled } from "@mui/system";
import { darkTheme } from "./theme";

const _styled = createStyled({ defaultTheme: darkTheme });

// This wrapper allows us to have transient props (something styled-components has but not Emotion yet)
// https://github.com/emotion-js/emotion/issues/2193#issuecomment-1039153870
// TODO: "any" types should be replaced by concrete types”
export const styled = (component: any, config?: any) => {
  config = {
    shouldForwardProp: (prop: string) => !prop.startsWith("$"),
    ...config,
  };

  return _styled(component, config);
};

import { styled } from "@mui/material";
import { SignInForm as SignInFormBase } from "@/features/auth/components/SignInForm";

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  overflow: auto;
`

export const SignInForm = styled(SignInFormBase)`
  margin: auto;
`

export enum TransactionDataGridField {
  MerchantName = "merchantName",
  MerchantTxnId = "merchantTxnId",
  PaymentSystemTxnId = "paymentSystemTxnId",
  InternalTxnId = "internalTxnId",
  PpaName = "ppaName",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  ApprovedAt = "approvedAt",
  HandledBy = "handledBy",
  Provider = "provider",
  PaymentMethod = "paymentMethod",
  Status = "status",
  MerchantAmount = "merchantAmount",
  MidAmount = "midAmount",
  ExchangeRate = "exchangeRate",
  Actions = "actions",
}

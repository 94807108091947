import { Divider as MuiDivider, styled } from "@mui/material";

export const Fields = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

export const Divider = styled(MuiDivider)`
  margin: 1rem 0;
  width: 100%;
`;

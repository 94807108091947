import { IActionsButtonItem } from "@/components/ActionsButton";

export enum TransactionActionTitle {
  TransactionDetails = "Transaction details",
  ResendCallbackToMerchant = "Resend callback to merchant",
  RequestStatusFromProvider = "Request status from provider",
  Release = "Release",
}

export interface ITransactionActionsItem
  extends IActionsButtonItem<TransactionActionTitle> {}

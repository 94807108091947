import { FC, ReactNode } from "react";
import { Typography } from "@mui/material";
import * as Styled from "./PageContent.styles";

interface IPageContentProps {
  title: string;
  children: ReactNode;
}

export const PageContent: FC<IPageContentProps> = ({ title, children }) => (
  <Styled.Root>
    <Typography variant="h5" mb={2}>
      {title}
    </Typography>
    {children}
  </Styled.Root>
);

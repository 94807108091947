import { forwardRef } from "react";
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from "react-router-dom";
import { createTheme } from "@mui/material";

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<ReactRouterLinkProps, "to"> & { href: ReactRouterLinkProps["to"] }
>(({ href, ...restProps }, ref) => {
  // Map href (Material UI) -> to (react-router-dom)
  return <ReactRouterLink ref={ref} to={href} {...restProps} />;
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiTab: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true,
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        spacing: {
          gap: "0.5rem",
        },
      },
    },
  },
});

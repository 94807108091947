import { useCallback, useMemo } from "react";
import { useSearchParams } from "@/hooks/useSearchParams";
import { IDataGridColumns } from "@/components/DataGrid";

interface IUseDataGridColumnsVisibilitySearchParams<TColumnField> {
  hiddenColumnFields: TColumnField[];
}

interface IUseDataGridColumnsVisibilityOptions<TColumns> {
  columns: TColumns;
}

export const useDataGridColumnsVisibility = <
  TColumns extends IDataGridColumns<any, any>,
  TColumnField,
>({
  columns,
}: IUseDataGridColumnsVisibilityOptions<TColumns>) => {
  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<IUseDataGridColumnsVisibilitySearchParams<TColumnField>>();

  const { hiddenColumnFields = [] } = parsedSearchParams;

  const visibleColumns = useMemo(() => {
    if (!hiddenColumnFields?.length) {
      return columns;
    }

    return columns.filter(
      (item) => !hiddenColumnFields.includes(item.field as TColumnField),
    );
  }, [columns, hiddenColumnFields]);

  const handleChangeVisibleColumns = useCallback(
    (data?: TColumnField[]) => {
      mergeSearchParams({
        hiddenColumnFields: data,
      });
    },
    [mergeSearchParams],
  );

  return {
    visibleColumns,
    hiddenColumnFields,
    handleChangeVisibleColumns,
  };
};

import {
  Table as TableMui,
  TableContainer as TableContainerBase,
  TableCell,
  Theme,
  alpha,
  tableRowClasses,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@/styles/styled";

import { dataGridClasses } from "./constants";

type StickyColumnSide = "left" | "right";

const getStickyStyles = (
  theme: Theme,
  stickySide?: StickyColumnSide,
  withShadow?: boolean,
) => {
  if (!stickySide) {
    return undefined;
  }

  const boxShadowOffsetX = `${stickySide === "right" ? "-" : ""}0.75rem`;

  return {
    position: "sticky",
    [stickySide]: 0,
    zIndex: 1,
    boxShadow: withShadow
      ? `${boxShadowOffsetX} 0 0.9375rem -0.8125rem ${alpha(
          theme.palette.common.black,
          0.4,
        )}`
      : "none",
  };
};

export const TableContainer = styled(TableContainerBase)<{
  $isFullyScrolledToLeft?: boolean;
  $isFullyScrolledToRight?: boolean;
}>(({ theme, $isFullyScrolledToLeft, $isFullyScrolledToRight }) => ({
  [`& .${dataGridClasses.caption}`]: {
    padding: 0,
  },
  [`& .${dataGridClasses.cellStickyLeft}`]: {
    ...getStickyStyles(theme, "left", !$isFullyScrolledToLeft),
  },
  [`& .${dataGridClasses.cellStickyRight}`]: {
    ...getStickyStyles(theme, "right", !$isFullyScrolledToRight),
  },
  [`& .${tableCellClasses.head}`]: {
    [`&.${dataGridClasses.cellStickyLeft}, &.${dataGridClasses.cellStickyRight}`]:
      {
        zIndex: 3,
      },
  },
}));

export const Table = styled(TableMui)(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    background: theme.palette.background.paper,
  },
  [`& .${tableRowClasses.hover}`]: {
    [`&:hover .${tableCellClasses.root}`]: {
      background: theme.palette.background.default,
    },
  },
}));

export const HeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  whiteSpace: "nowrap",
  textTransform: "uppercase",
  color: theme.palette.text.primary,
}));

import { useMemo } from "react";
import { FieldValues } from "react-hook-form";
import {
  FormCheckboxTreeSelectField,
  FormCheckboxTreeSelectFieldProps,
  CheckboxTreeValues,
  Transform,
} from "@/components/form/FormCheckboxTreeSelectField";
import { MerchantId } from "@/types/merchant";
import { IOperator, OperatorId, Operators } from "@/types/operator";

interface IOperatorsAndMerchantsFieldProps<TFormValues extends FieldValues>
  extends Omit<FormCheckboxTreeSelectFieldProps<TFormValues>, "nodes"> {
  operators: Operators;
  valueSeparator?: string;
}

export const OperatorsAndMerchantsField = <TFormValues extends FieldValues>({
  label = "Operators/Merchants",
  valueSeparator = "__",
  withInverse,
  operators,
  ...restProps
}: IOperatorsAndMerchantsFieldProps<TFormValues>) => {
  const { checkboxTreeNodes, operatorIdByMerchantIdMap, normalizedOperators } =
    useMemo(
      () => preprocessOperators(operators, valueSeparator),
      [operators, valueSeparator],
    );

  const transform = useMemo<
    Transform<{
      operatorIds: OperatorId[];
      merchantIds: MerchantId[];
    }>
  >(
    () => ({
      input: (value) => {
        const { merchantIds = [] } = value || {};

        return merchantIds.map((merchantId) => {
          const operatorId = operatorIdByMerchantIdMap[merchantId];

          return `${operatorId}${valueSeparator}${merchantId}`;
        });
      },
      output: (checkedValues: CheckboxTreeValues) => {
        let operatorIds = new Set<OperatorId>();
        const merchantIds = new Set<MerchantId>();

        const selectedMerchantsCountByOperatorId: Record<OperatorId, number> =
          {};

        checkedValues.forEach((checkedValue) => {
          const [operatorId, merchantId] = checkedValue
            .toString()
            .split(valueSeparator);

          const parsedOperatorId = parseInt(operatorId);
          const parsedMerchantId = parseInt(merchantId);

          selectedMerchantsCountByOperatorId[parsedOperatorId] =
            (selectedMerchantsCountByOperatorId[parsedOperatorId] || 0) + 1;

          operatorIds.add(parsedOperatorId);
          merchantIds.add(parsedMerchantId);
        });

        if (withInverse) {
          operatorIds = new Set(
            [...operatorIds].filter((operatorId) => {
              const operator = normalizedOperators[operatorId];

              return (
                operator.merchants.length ===
                selectedMerchantsCountByOperatorId[operatorId]
              );
            }),
          );
        }

        return {
          operatorIds: [...operatorIds],
          merchantIds: [...merchantIds],
        };
      },
    }),
    [
      operatorIdByMerchantIdMap,
      valueSeparator,
      normalizedOperators,
      withInverse,
    ],
  );

  return (
    <FormCheckboxTreeSelectField
      label={label}
      nodes={checkboxTreeNodes}
      transform={transform}
      withInverse={withInverse}
      {...restProps}
    />
  );
};

const preprocessOperators = (data: Operators, valueSeparator: string) => {
  const operatorIdByMerchantIdMap: Record<MerchantId, OperatorId> = {};
  const normalizedOperators: Record<OperatorId, IOperator> = {};

  const checkboxTreeNodes = data.map((operator) => {
    const { id: operatorId, name: operatorName, merchants } = operator;

    normalizedOperators[operatorId] = operator;

    return {
      value: operatorId,
      label: operatorName,
      children: merchants.map(({ id: merchantId, name: merchantName }) => {
        operatorIdByMerchantIdMap[merchantId] = operatorId;

        return {
          value: `${operatorId}${valueSeparator}${merchantId}`,
          label: merchantName,
        };
      }),
    };
  });

  return {
    checkboxTreeNodes,
    operatorIdByMerchantIdMap,
    normalizedOperators,
  };
};

import { UIEvent, useCallback, useRef, useState } from "react";
import { IDataGridScrollData } from "../types";

export interface IUseDataGridScrollData {
  onScroll?: (
    data: IDataGridScrollData,
    event: UIEvent<HTMLDivElement>
  ) => void;
}

export const useDataGridScroll = ({ onScroll }: IUseDataGridScrollData) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [scrollData, setScrollData] = useState<IDataGridScrollData>(() => ({
    edgeOffset: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  }));

  const handleScrollChange = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const {
        clientWidth,
        clientHeight,
        scrollWidth,
        scrollHeight,
        scrollLeft,
        scrollTop,
      } = event.currentTarget;

      const beforeBottomEdgeOffset = Math.floor(
        scrollHeight - clientHeight - scrollTop
      );
      const beforeRightEdgeOffset = Math.floor(
        scrollWidth - clientWidth - scrollLeft
      );

      const data: IDataGridScrollData = {
        edgeOffset: {
          top: scrollTop,
          bottom: beforeBottomEdgeOffset,
          left: scrollLeft,
          right: beforeRightEdgeOffset,
        },
      };

      if (onScroll) {
        onScroll(data, event);
      }

      setScrollData(data);
    },
    [onScroll]
  );

  return {
    scrollData,
    tableContainerRef,
    handleScrollChange,
  };
};

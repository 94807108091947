import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Button } from "@/components/Button";
import { FormPasswordField, FormTextField } from "@/components/form";
import {
  openEmailVerificationDialog,
  openTwoFactorVerificationDialog,
} from "@/features/auth/auth.slice";
import {
  useLazyGetUserDataQuery,
  useSignInMutation,
} from "@/features/auth/auth.api";
import { UserAuthType } from "@/types/user";
import { AuthStatus } from "@/features/auth/types";
import { useAppDispatch } from "@/hooks/useAppDispatch";
import { handleError } from "@/utils/handleError";
import * as Styled from "./SignInForm.styles";

enum FieldName {
  Username = "username",
  Password = "password",
}

const formSchema = yup
  .object({
    [FieldName.Username]: yup
      .string()
      .required()
      .email("Please double-check if the email is correct.")
      .trim()
      .label("Email"),
    [FieldName.Password]: yup.string().required().trim().label("Password"),
  })
  .required();

type FormValues = yup.InferType<typeof formSchema>;

interface ISignInFormProps {
  className?: string;
}

export const SignInForm: FC<ISignInFormProps> = ({ className }) => {
  const [signIn, { isLoading }] = useSignInMutation();
  const [getUserData] = useLazyGetUserDataQuery();

  const dispatch = useAppDispatch();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(formSchema),
    mode: "onBlur",
  });

  const handleFormSubmit = useCallback(
    async (values: FormValues) => {
      try {
        const { authStatus } = await signIn(values).unwrap();

        const handlerByAuthStatus: Record<AuthStatus, () => void> = {
          [AuthStatus.NotAuthorized]: () => {
            toast.error(
              "Please double-check your email and password and try again!",
            );
          },
          [AuthStatus.Authorized]: () => {
            getUserData();
            toast.success("Welcome!");
          },
          [AuthStatus.RequiredEmailVerification]: () => {
            dispatch(openEmailVerificationDialog());
          },
          [AuthStatus.WaitingEmailAuthCode]: () => {
            dispatch(openTwoFactorVerificationDialog(UserAuthType.Email));
            toast.info(
              "2-factor code sent to your email. Please check your inbox.",
            );
          },
          [AuthStatus.WaitingGoogleAuthCode]: () => {
            dispatch(
              openTwoFactorVerificationDialog(
                  UserAuthType.GoogleAuthenticator,
              ),
            );
            toast.info(
              "Please check your Google Authenticator app for your 2-factor code.",
            );
          },
        };

        const handler = handlerByAuthStatus[authStatus];

        handler();
      } catch (error) {
        handleError(error);
      }
    },
    [signIn, dispatch, getUserData],
  );

  return (
    <Styled.Form
      className={className}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <FormTextField
        name={FieldName.Username}
        control={control}
        label="Email"
        disabled={isLoading}
      />
      <FormPasswordField
        name={FieldName.Password}
        control={control}
        disabled={isLoading}
      />
      <Button type="submit" fullWidth isLoading={isLoading}>
        Sign In
      </Button>
    </Styled.Form>
  );
};

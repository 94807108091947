import {
  CheckboxTreeNodes,
  CheckboxTreeValues,
} from "@/components/CheckboxTree/types";

export const getAllNodesValues = (
  nodes: CheckboxTreeNodes = [],
): CheckboxTreeValues => {
  return nodes.reduce((accumulator, node) => {
    if (!node.children?.length) {
      return accumulator.concat(node.value);
    }

    return accumulator.concat(getAllNodesValues(node.children));
  }, [] as CheckboxTreeValues);
};

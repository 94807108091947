import { ReactElement } from "react";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from "@mui/material";
import { Control, Controller, Path, FieldValues } from "react-hook-form";
import * as Styled from "./FormCheckboxField.styles";

type FormCheckboxFieldProps<TFormValues extends FieldValues> = Omit<
  CheckboxProps,
  "checked"
> & {
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  label: FormControlLabelProps["label"];
  helperText?: string;
};

export const FormCheckboxField = <TFormValues extends FieldValues>({
  className,
  name,
  control,
  label,
  helperText,
  onChange,
  ...restProps
}: FormCheckboxFieldProps<TFormValues>): ReactElement => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => {
      const { value, onChange: onChangeField, ...restField } = field;

      return (
        <Styled.FormControl className={className} error={!!error}>
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                checked={!!value}
                onChange={(e, checked) => {
                  onChangeField(checked);

                  if (onChange) {
                    onChange(e, checked);
                  }
                }}
                {...restField}
                {...restProps}
              />
            }
          />
          <FormHelperText>{error?.message || helperText}</FormHelperText>
        </Styled.FormControl>
      );
    }}
  />
);

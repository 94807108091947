import { FC, ReactNode, useMemo } from "react";
import { IUserPermissions } from "@/types/user";
import { useUser } from "@/hooks/useUser";

export interface IPermissionsRestrictedProps {
  predicate: (permissions: IUserPermissions) => boolean;
  children: ReactNode;
  renderNotPermitted?: () => ReactNode;
}

export const PermissionsRestricted: FC<IPermissionsRestrictedProps> = ({
  predicate,
  children,
  renderNotPermitted,
}) => {
  const { permissions } = useUser();

  const isPermitted = useMemo(() => {
    return predicate(permissions);
  }, [permissions, predicate]);

  if (isPermitted) {
    return children;
  }

  if (renderNotPermitted) {
    return renderNotPermitted();
  }

  return null;
};

import { FC } from "react";
import ReactJson, { ReactJsonViewProps } from "react-json-view";

interface IJsonViewerProps extends ReactJsonViewProps {}

export const JsonViewer: FC<IJsonViewerProps> = (props) => {
  return (
    <ReactJson
      theme="threezerotwofour"
      collapsed={1}
      displayObjectSize={false}
      displayDataTypes={false}
      enableClipboard={false}
      {...props}
    />
  );
};

import { FC, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import {
  UserFormDefaultValues,
  UserFormDialog,
  UserFormValues,
} from "@/features/users/components/UserFormDialog";
import { useCreateUserMutation } from "@/features/users/usersApi";
import { useUser } from "@/hooks/useUser";
import { UserAuthType } from "@/types/user";
import { UserStatus } from "@/features/users/types";

interface IUserCreateDialogProps {
  open: boolean;
}

export const UserCreateDialog: FC<IUserCreateDialogProps> = ({ open }) => {
  const [createUser, { isLoading }] = useCreateUserMutation();
  const { user: currentUser } = useUser();

  const defaultValues: UserFormDefaultValues = useMemo(
    () => ({
      authType: UserAuthType.GoogleAuthenticator,
      status: UserStatus.Inactive,
      ipWhiteList: (currentUser?.ipWhiteList || []).map((ip) => ({
        value: ip,
      })),
    }),
    [currentUser],
  );

  const handleSubmit = useCallback(
    async ({
      fullName,
      operatorsAndMerchantsBlackList,
      merchantIdBlackList,
      ipWhiteList = [],
      ...restValues
    }: UserFormValues) => {
      await createUser({
        ...restValues,
        fullName,
        operatorIdBlackList: operatorsAndMerchantsBlackList.operatorIds || [],
        merchantIdBlackList:
          merchantIdBlackList ||
          operatorsAndMerchantsBlackList.merchantIds ||
          [],
        ipWhiteList: ipWhiteList.map(({ value }) => value),
      }).unwrap();

      toast.success(`${fullName} has been created.`);
    },
    [createUser],
  );

  return (
    <UserFormDialog
      title="Create User"
      submitButtonText="Create"
      defaultValues={defaultValues}
      isSubmitting={isLoading}
      onSubmit={handleSubmit}
      open={open}
    />
  );
};

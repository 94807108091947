import { FieldValues } from "react-hook-form";
import { MaskedInputProps } from "react-text-mask";
import {
  FormTextField,
  FormTextFieldProps,
} from "@/components/form/FormTextField";

const maskedInputProps: MaskedInputProps = {
  guide: false,
  mask: (value) => {
    const mask = Array(value.length).fill(/[\d.]/);
    const chunks = value.split(".");

    for (let i = 0; i < 4; i += 1) {
      const chunk = chunks[i] || "";

      if (255 % +chunk === 255) {
        mask[value.length - 1] = ".";
        mask[value.length] = /[\d.]/;
      }
    }

    return mask;
  },
  pipe: (value) => {
    if (value === "." || value.endsWith("..")) {
      return false;
    }

    const parts = value.split(".");

    if (
      parts.length > 4 ||
      parts.some((part: string) => part === "00" || +part < 0 || +part > 255)
    ) {
      return false;
    }

    return value;
  },
};

export const FormIpAddressField = <TFormValues extends FieldValues>({
  label = "IP Address",
  ...restProps
}: FormTextFieldProps<TFormValues>) => (
  <FormTextField
    label={label}
    maskedInputProps={maskedInputProps}
    {...restProps}
  />
);

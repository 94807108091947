import { FC } from "react";
import {
  IDepositTransaction,
  IWithdrawalTransaction,
} from "@/features/transactionsManager/types";
import { useTransactionActions } from "@/features/transactionsManager/components/TransactionActionsButton/useTransactionActions";
import { ActionsButton } from "@/components/ActionsButton";

interface ITransactionActionsButtonProps {
  transaction: IDepositTransaction | IWithdrawalTransaction;
}

export const TransactionActionsButton: FC<ITransactionActionsButtonProps> = ({
  transaction,
}) => {
  const { transactionActionsItems } = useTransactionActions({ transaction });

  return <ActionsButton items={transactionActionsItems} />;
};

export enum TransactionFiltersFieldName {
  OperatorsAndMerchants = "operatorsAndMerchants",
  MerchantIds = "merchantIds",
  DateType = "dateType",
  DateRange = "dateRange",
  FromDate = "fromDate",
  ToDate = "toDate",
  UtcOffset = "utcOffset",
  PpaNames = "ppaNames",
  Providers = "providers",
  PaymentMethods = "paymentMethods",
  Statuses = "statuses",
  MerchantCurrency = "merchantCurrency",
  MidCurrency = "midCurrency",
  MerchantTransactionId = "merchantTxnId",
  SystemTransactionId = "internalTxnId",
  PspTransactionId = "paymentSystemTxnId",
  KeywordType = "keywordType",
  Keyword = "keyword",
  BlockedOnly = "blockedOnly",
}

export enum DateRange {
  Today = "Today",
  Yesterday = "Yesterday",
  CurrentWeek = "Current week",
  CurrentMonth = "Current month",
  LastWeek = "Last week",
  Last30Days = "Last 30 days",
  Last180Days = "Last 180 days",
  LastYear = "Last year",
  CustomPeriod = "Custom period",
}

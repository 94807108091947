import { useCallback, useState } from "react";

export const useCacheBuster = () => {
  const [cacheBuster, setCacheBuster] = useState<number>(Date.now());

  const bustCache = useCallback(() => {
    setCacheBuster(Date.now());
  }, []);

  return {
    cacheBuster,
    bustCache,
  };
};

import { useMemo } from "react";
import { TransactionType } from "@/types/transaction";
import {
  IDepositTransaction,
  IWithdrawalTransaction,
} from "@/features/transactionsManager/types";
import { TransactionActionsButton } from "@/features/transactionsManager/components/TransactionActionsButton";
import { TransactionDataGridField } from "@/features/transactionsManager/components/TransactionDataGrid/types";
import { IDataGridColumn } from "@/components/DataGrid";
import { UserRole } from "@/types/user";
import { useUser } from "@/hooks/useUser";
import { formatDate } from "@/utils/dataFormat/formatDate";
import { formatCurrency } from "@/utils/dataFormat/formatCurrency";
import { SxProps, Theme } from "@mui/material";
import * as Styled from "./TransactionDataGrid.styles";

type ITransactionDataGridColumn = IDataGridColumn<
  IDepositTransaction | IWithdrawalTransaction,
  TransactionDataGridField
>;

export type TransactionDataGridColumns = ITransactionDataGridColumn[];

interface IUseTransactionDataGridColumnsParams {
  transactionType: TransactionType;
}

export const useTransactionDataGridColumns = ({
  transactionType,
}: IUseTransactionDataGridColumnsParams) => {
  const { user } = useUser();

  const transactionColumns = useMemo<TransactionDataGridColumns>(() => {
    const isVisibleByColumn: Partial<
      Record<TransactionDataGridField, () => boolean>
    > = {
      [TransactionDataGridField.ApprovedAt]: () =>
        transactionType === TransactionType.Withdrawal,
      [TransactionDataGridField.HandledBy]: () =>
        transactionType === TransactionType.Withdrawal,
      [TransactionDataGridField.MerchantName]: () =>
        user?.role !== UserRole.Merchant,
    };

    const headCellStyleCommon: SxProps<Theme> = {
      whiteSpace: "wrap",
    };

    const columnsMap: Record<
      TransactionDataGridField,
      ITransactionDataGridColumn
    > = {
      [TransactionDataGridField.MerchantName]: {
        field: TransactionDataGridField.MerchantName,
        headerName: "Merchant",
        sortKey: TransactionDataGridField.MerchantName,
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.MerchantTxnId]: {
        field: TransactionDataGridField.MerchantTxnId,
        headerName: "Merchant txn ID",
        sortKey: TransactionDataGridField.MerchantTxnId,
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.PaymentSystemTxnId]: {
        field: TransactionDataGridField.PaymentSystemTxnId,
        headerName: "PSP txn ID",
        sortKey: TransactionDataGridField.PaymentSystemTxnId,
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.InternalTxnId]: {
        field: TransactionDataGridField.InternalTxnId,
        headerName: "System txn ID",
        sortKey: TransactionDataGridField.InternalTxnId,
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.PpaName]: {
        field: TransactionDataGridField.PpaName,
        headerName: "PPA name",
        sortKey: TransactionDataGridField.PpaName,
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.CreatedAt]: {
        field: TransactionDataGridField.CreatedAt,
        headerName: "Created at",
        sortKey: TransactionDataGridField.CreatedAt,
        renderCell: ({ rowData }) => formatDate(rowData.createdAt),
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.UpdatedAt]: {
        field: TransactionDataGridField.UpdatedAt,
        headerName: "Updated at",
        sortKey: TransactionDataGridField.UpdatedAt,
        renderCell: ({ rowData }) => formatDate(rowData.updatedAt),
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.ApprovedAt]: {
        field: TransactionDataGridField.ApprovedAt,
        headerName: "Approved at",
        sortKey: TransactionDataGridField.ApprovedAt,
        renderCell: ({ rowData }) => {
          if ("approvedAt" in rowData && rowData.approvedAt) {
            return formatDate(rowData.approvedAt);
          }
        },
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.HandledBy]: {
        field: TransactionDataGridField.HandledBy,
        headerName: "Handled by",
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.Provider]: {
        field: TransactionDataGridField.Provider,
        headerName: "Provider",
        sortKey: TransactionDataGridField.Provider,
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.PaymentMethod]: {
        field: TransactionDataGridField.PaymentMethod,
        headerName: "Payment Method",
        sortKey: TransactionDataGridField.PaymentMethod,
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.Status]: {
        field: TransactionDataGridField.Status,
        headerName: "Status",
        sortKey: TransactionDataGridField.Status,
        renderCell: ({ rowData: { status } }) => {
          return (
            <Styled.Cell>
              <Styled.StatusIcon $status={status} />
              {status}
            </Styled.Cell>
          );
        },
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.MerchantAmount]: {
        field: TransactionDataGridField.MerchantAmount,
        headerName: "Amount Merchant Currency",
        sortKey: TransactionDataGridField.MerchantAmount,
        renderCell: ({ rowData: { merchantAmount, merchantCurrency } }) => {
          return formatCurrency(merchantAmount, merchantCurrency);
        },
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.MidAmount]: {
        field: TransactionDataGridField.MidAmount,
        headerName: "Amount MID Currency",
        sortKey: TransactionDataGridField.MidAmount,
        renderCell: ({ rowData: { midAmount, midCurrency } }) => {
          return formatCurrency(midAmount, midCurrency);
        },
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.ExchangeRate]: {
        field: TransactionDataGridField.ExchangeRate,
        headerName: "Exchange rate",
        sortKey: TransactionDataGridField.ExchangeRate,
        headCellSx: headCellStyleCommon,
      },
      [TransactionDataGridField.Actions]: {
        field: TransactionDataGridField.Actions,
        headerName: "Actions",
        headCellSx: {
          ...headCellStyleCommon,
          width: "1px",
        },
        renderCell: ({ rowData }) => (
          <TransactionActionsButton transaction={rowData} />
        ),
      },
    };

    return Object.values(TransactionDataGridField).reduce(
      (accumulator, key) => {
        const isVisiblePredicate = isVisibleByColumn[key];

        if (isVisiblePredicate && !isVisiblePredicate()) {
          return accumulator;
        }

        return [...accumulator, columnsMap[key]];
      },
      [] as TransactionDataGridColumns,
    );
  }, [transactionType, user?.role]);
  return {
    transactionColumns,
  };
};

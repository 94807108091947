import React, { FC, useMemo } from "react";
import { CheckboxTreeSelect } from "@/components/CheckboxTreeSelect";
import { TransactionDataGridColumns } from "@/features/transactionsManager/components/TransactionDataGrid/useTransactionDataGridColumns";
import { TransactionDataGridField } from "@/features/transactionsManager/components/TransactionDataGrid/types";

interface ITransactionEditColumnsProps {
  className?: string;
  columns: TransactionDataGridColumns;
  hiddenColumnFields: TransactionDataGridField[];
  onChange: (hiddenColumnFields: TransactionDataGridField[]) => void;
}

export const TransactionEditColumns: FC<ITransactionEditColumnsProps> = ({
  className = "",
  columns,
  hiddenColumnFields,
  onChange,
}) => {
  const checkboxTreeNodes = useMemo(() => {
    return columns.map((column) => ({
      label: column.headerName,
      value: column.field,
    }));
  }, [columns]);

  return (
    <CheckboxTreeSelect
      className={className}
      label="Edit columns"
      checkedValues={hiddenColumnFields || []}
      nodes={checkboxTreeNodes}
      name="visibleColumns"
      withInverse
      onChange={(value) => {
        onChange(value as TransactionDataGridField[]);
      }}
    />
  );
};

import { styled, TablePagination } from "@mui/material";
import { TransactionFilters as TransactionFiltersBase } from "@/features/transactionsManager/components/TransactionFilters";

export const TransactionFilters = styled(TransactionFiltersBase)`
  margin: 1rem 0;
`;

export const TransactionPagination = styled(TablePagination)`
  flex-shrink: 0;
`;

import { useMemo } from "react";
import { FieldValues } from "react-hook-form";
import {
  FormCheckboxTreeSelectField,
  FormCheckboxTreeSelectFieldProps,
} from "@/components/form/FormCheckboxTreeSelectField";
import { Merchants } from "@/types/merchant";

interface IMerchantsFieldProps<TFormValues extends FieldValues>
  extends Omit<FormCheckboxTreeSelectFieldProps<TFormValues>, "nodes"> {
  merchants: Merchants;
}

export const MerchantsField = <TFormValues extends FieldValues>({
  label = "Merchants",
  merchants,
  ...restProps
}: IMerchantsFieldProps<TFormValues>) => {
  const { checkboxTreeNodes } = useMemo(
    () => preprocessMerchants(merchants),
    [merchants],
  );

  return (
    <FormCheckboxTreeSelectField
      label={label}
      nodes={checkboxTreeNodes}
      {...restProps}
    />
  );
};

const preprocessMerchants = (merchants: Merchants) => {
  const checkboxTreeNodes = merchants.map((merchant) => {
    const { id, name } = merchant;

    return {
      value: id,
      label: name,
    };
  });

  return {
    checkboxTreeNodes,
  };
};

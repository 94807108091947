import { TransactionId, TransactionType } from "@/types/transaction";
import { OperatorId, Operators } from "@/types/operator";
import { MerchantId, Merchants } from "@/types/merchant";
import {
  IFilteredRequestData,
  IPaginatedRequestData,
  IPaginatedResponseBody,
  IRequestDataWithSorting,
} from "@/types/base";

export enum TransactionStatus {
  Initiated = "initiated",
  InProgress = "in-progress",
  Pending = "pending",
  Processing = "processing",
  Failed = "failed",
  Confirmed = "confirmed",
  Cancelled = "cancelled",
  Declined = "declined",
}

interface IBaseTransaction {
  merchantName: string;
  merchantTxnId: string;
  paymentSystemTxnId: string;
  internalTxnId: TransactionId;
  ppaName: string;
  createdAt: string;
  updatedAt: string;
  paymentMethod: string;
  merchantCurrency: string;
  midCurrency: string | null;
  merchantAmount: number;
  midAmount: number | null;
  exchangeRate: number;
  paymentSystemMessage: string;
  provider: string;
  instrument: string;
  instrumentType: string;
  status: TransactionStatus;
}

export interface IDepositTransaction extends IBaseTransaction {}

export interface IWithdrawalTransaction extends IBaseTransaction {
  handledBy: string;
  isApproved: boolean;
  approvedAt?: string;
}

export interface ITransactionEvent {
  internalTxnId: TransactionId;
  event: string;
  createdAt: string;
  data: object | string | null;
  status: string;
}

interface IGetCommonTransactionsFiltersRequestData {
  dateType?: string;
  utcOffset?: string;
  fromDate?: string;
  toDate?: string;
  operatorIds?: OperatorId[];
  merchantIds?: MerchantId[];
  ppaNames?: string[];
  providers?: string[];
  paymentMethods?: string[];
  statuses?: string[];
  merchantCurrency?: string;
  midCurrency?: string;
  merchantTxnId?: string;
  internalTxnId?: TransactionId;
  paymentSystemTxnId?: string;
  keywordType?: string;
  keyword?: string;
}

export interface IGetDepositTransactionsFiltersRequestData
  extends IGetCommonTransactionsFiltersRequestData {}

export interface IGetWithdrawTransactionsFiltersRequestData
  extends IGetCommonTransactionsFiltersRequestData {
  blockedOnly?: boolean;
}

export interface IGetTransactionsFiltersResponseBody {
  operators?: Operators;
  merchants?: Merchants;
  ppaNames: string[];
  providers: string[];
  statuses: string[];
  paymentMethods: string[];
  merchantCurrencies: string[];
  midCurrencies: string[];
  isLiveFilter: boolean;
}

export interface IGetDepositTransactionsResponseData
  extends IPaginatedResponseBody<IDepositTransaction[]> {}

export interface IGetWithdrawalTransactionsResponseData
  extends IPaginatedResponseBody<IWithdrawalTransaction[]> {}

export interface IGetDepositTransactionsRequestData
  extends IPaginatedRequestData,
    IRequestDataWithSorting<keyof IDepositTransaction>,
    IFilteredRequestData<IGetDepositTransactionsFiltersRequestData> {}

export interface IGetWithdrawalTransactionsRequestData
  extends IPaginatedRequestData,
    IRequestDataWithSorting<keyof IWithdrawalTransaction>,
    IFilteredRequestData<IGetWithdrawTransactionsFiltersRequestData> {}

export interface TransactionsDetailsRequestBody {
  id: TransactionId;
  transactionType: TransactionType;
}

export interface ITransactionDetailsResponseBody {
  events: ITransactionEvent[];
}

export interface ITransactionsExportResponseBody {
  token: string;
}
